import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleCheck,
  faCircleExclamation,
  faCircleXmark,
  faEnvelope,
  faEye,
  faEyeSlash,
  faFilePdf,
  faMapMarkerAlt,
  faMapPin,
  faPen,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { EventListItemData, IFile } from './Events3'
import { UserStoreContext } from '../../store/userStore'
import {
  EVENT3_TYPE,
  Event3StoreContext,
  EventInfoResponse,
  EventTimeSmallInterface,
} from '../../store/event3Store'
import { Tag } from '../../components/Tag'
import { Button } from '../../components/Button'
import { faWaveform } from '@fortawesome/pro-solid-svg-icons'
import { USER_ROLE_TYPE } from '../../Enums/TyraEnums'
import { CommentInput } from './components/CommentInput'
import { CommentDetailCard } from './components/CommentDetailCard'
import EventParticipatingPopUp from './components/EventParticipatingPopUp'
import { observer } from 'mobx-react'
import { EventOverview } from './components/EventOverview'
import { IKidRelation } from './components/OverviewUsersList'
import { EventReqResOverview } from './EventReqResOverview'
import { SettingsDetailView } from './components/SettingsDetailView'
import { EventExpendableCardHeader } from './components/EventDetailCard'
import { useClickOutside } from '../../hooks/useClickoutside'
import { ButtonDropdown } from '../../components/Dropdown'
import ModalRegular from '../../components/ModalRegular'
import { FreeTimesCard } from './components/FreeTimesCard'
import { RebookTime } from './components/RebookTime'
import { BookingFinalStage } from './components/BookingFinalStage'
import { KidsStoreContext } from '../../store/kidsStore'
import { Kid } from '../../Models/Kid'
import { UserRole } from '../../Models/UserRole'
import Swal from 'sweetalert2'
import { Loading } from '../../components/Loading'

export interface IEventInfoOverviewUser {
  id: string
  userId: string
  name: string
  thumbnail?: string
  departmentIds: (string | undefined)[]
  departmentNames?: (string | undefined)[]
  kids?: IKidRelation[]
  hasRead?: boolean
  readAt?: string
  isUser: boolean
}

export interface IEventActivitiesOverviewUser {
  id: string
  userId: string
  kidId?: string
  name: string
  thumbnail?: string
  departmentIds: Array<string | undefined>
  relatives?: IKidRelation[]
  kids?: IKidRelation[]
  status?: string
  message?: string
  cancelledAt?: string
  extraParticipants?: number
  isUser: boolean
}

export interface IEventOverview {
  eventTimeId: string
  eventGroupInfoId: string
  departmentIds: string[]
  readCount: number
  unreadCount: number
  allCount: number
  readUsers: IEventInfoOverviewUser[]
  unreadUsers: IEventInfoOverviewUser[]
  notRepliedParticipants: IEventInfoOverviewUser[]
  lastReminderSent?: string
}

export interface IEventDetails extends IEventOverview {
  presentKidsCount: number
  acceptedCount: number
  declinedCount: number
  hasMessageCount: number
  notRepliedCount: number
  presentKids: IEventActivitiesOverviewUser[]
  acceptedParticipants: IEventInfoOverviewUser[]
  declinedParticipants: IEventInfoOverviewUser[]
  hasMessageParticipants: IEventInfoOverviewUser[]
}
export const EventDetailPage: React.FC<{
  eventTimeId?: string
  fetchedEventDetail?: EventListItemData
  triggerHasResponded?: () => void
  hasResponded: boolean
  isExpanded?: boolean
  handleExpaneded: () => void
  setFetchedEventDetail: (value: EventListItemData) => void
}> = observer(
  ({
    eventTimeId,
    fetchedEventDetail,
    triggerHasResponded,
    hasResponded,
    isExpanded,
    handleExpaneded,
    setFetchedEventDetail,
  }) => {
    const { t } = useTranslation()
    const { currentUserRole } = useContext(UserStoreContext)
    const {
      getEvent3Overview,
      declineParticipatingInEvent,
      rebookToEventTime,
      getEvents,
      editBookingToEventTime,
      declineBookingToEventTime,
    } = useContext(Event3StoreContext)
    /* const [fetchedEventDetail, setFetchedEventDetail] =
    useState<EventListItemData>() */
    const [fetchedEventOverview, setFetchedEventOverview] =
      useState<IEventDetails>()
    const [isPopupVisible, setPopupVisible] = useState(false)
    const [isOverviewVisible, setOverviewVisible] = useState(false)
    const [openSttings, setOpenSettings] = useState<boolean>(false)
    /* const [dropdownVisible, setDropdownVisible] = useState(false)
    const [menuRef, togglerRef] = useClickOutside(() => {
      setDropdownVisible(false)
    }) */
    const { fetchGuardiansForKids, guardians } = useContext(KidsStoreContext)
    const [selectedTime, setSelectedTime] =
      useState<EventTimeSmallInterface | null>(null)

    const [choosePresentGuardians, setChoosePresentGuardians] = useState(false)
    const [timeRebookable, setTimeRebookable] = useState(true)
    const [presentGuardians, setPresentGuardians] = useState<UserRole[]>([])
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const [showRebook, setShowRebook] = useState(false)
    const [showEditAttendees, setShowEditAttendees] = useState(false)
    const [showCancelBooking, setShowCancelBooking] = useState(false)
    const [openHelpSection, setOpenHelpSection] = useState<boolean>(false)

    /* const createdAt = moment(fetchedEventDetail?.createdAt).format(
      'DD MMMM YYYY',
    ) */
    const validUserToSeeOverview = currentUserRole?.role_type
      ? currentUserRole?.role_type > USER_ROLE_TYPE.PARENT
      : false

    const infoType = fetchedEventDetail?.type === EVENT3_TYPE.INFO
    const bookingType = fetchedEventDetail?.type === EVENT3_TYPE.BOOKING
    const hasComments =
      fetchedEventDetail?.type === EVENT3_TYPE.BOOKING
        ? fetchedEventDetail?.comments.length > 0
          ? true
          : fetchedEventDetail.allowComments
        : fetchedEventDetail?.comments
        ? fetchedEventDetail?.comments.length >= 0
        : false
    const locationType = fetchedEventDetail?.location?.type
    const sortedComments = fetchedEventDetail?.comments

    useEffect(() => {
      let isMounted = true
      if (validUserToSeeOverview) {
        const fetchEventOverview = async () => {
          try {
            const eventOverview = await getEvent3Overview(
              eventTimeId,
              fetchedEventDetail?.eventGroupInfoId,
              fetchedEventDetail?.departmentIds ?? [],
            )
            if (isMounted) {
              setFetchedEventOverview(eventOverview)
            }
          } catch (error) {
            console.error('Error fetching event detail: ', error)
          }
        }
        fetchEventOverview()
      }
      return () => {
        isMounted = false
      }
    }, [fetchedEventDetail, hasResponded])

    useEffect(() => {
      if (showRebook && fetchedEventDetail?.kidId) {
        fetchGuardiansForKids(
          Kid.createWithoutData(fetchedEventDetail.kidId) as Kid,
        )
      }
    }, [showRebook])

    /* const handlePlayAudio = (file: IFile) => {
      const newWindow = window.open('', '_blank')
      newWindow?.document.write('<!DOCTYPE html>')
      newWindow?.document.write('<html lang="en">')
      newWindow?.document.write('<head>')
      newWindow?.document.write('<meta charset="UTF-8">')
      newWindow?.document.write(
        '<meta name="viewport" content="width=device-width, initial-scale=1.0">',
      )
      newWindow?.document.write('<title>Audio Player</title>')
      newWindow?.document.write('<style>')
      newWindow?.document.write(`
    body {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      margin: 0;
    }
    audio {
      width: 50%;
      max-width: 500px;
    }
  `)
      newWindow?.document.write('</style>')
      newWindow?.document.write('</head>')
      newWindow?.document.write('<body>')
      newWindow?.document.write('<audio controls autoplay>')
      newWindow?.document.write(
        `<source src="${file.file}" type="${file.mime_type}">`,
      )
      newWindow?.document.write(
        'Your browser does not support the audio element.',
      )
      newWindow?.document.write('</audio>')
      newWindow?.document.write('</body>')
      newWindow?.document.write('</html>')
      newWindow?.document.close()
    }
*/
    const handleOpenPopup = () => {
      setPopupVisible(true)
    }

    const handleClosePopup = () => {
      setPopupVisible(false)
    }

    /* const handleDeclineParticipatingInEvent = async () => {
      await declineParticipatingInEvent(
        fetchedEventDetail?.eventTimeId,
        [currentUserRole?.user?.id ?? ''],
        [currentUserRole?.kid?.id ?? ''],
      )
      triggerHasResponded?.()
    }

    const handleAcceptParticipatingInEvent = async () => {
      setPopupVisible(true)
      triggerHasResponded?.()
    } */

    /* const eventHasPassed = moment(fetchedEventDetail?.eventDate).isBefore(
      moment().startOf('day'),
    )
    const handleEditParticipating = (
      fetchedEventDetail: EventListItemData | undefined,
    ) => {
      let content = null

      if (!eventHasPassed) {
        if (fetchedEventDetail?.bookingStatus === 'attending') {
          if (
            fetchedEventDetail?.ownParticipantsData?.extraParticipants ===
            undefined
          ) {
            content = (
              <div className="flex flex-col justify-center items-center w-full mt-auto">
                <p className="font-semibold">
                  {fetchedEventDetail.eventFor === 'kid' &&
                  currentUserRole?.attributes.kid
                    ? currentUserRole?.attributes.kid.kidName +
                      ' ' +
                      t('events.registered')
                    : t('events.youRegistered')}
                </p>
                <p className="font-semibold my-1 px-3 w-[90%] flex justify-center text-sm">
                  {fetchedEventDetail?.ownParticipantsData?.message ?? ''}{' '}
                </p>
                <p
                  className="font-semibold text-blue-600 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleOpenPopup()
                  }}
                >
                  {t('blog.edit')}
                </p>
              </div>
            )
          } else {
            content = (
              <div className="flex flex-col justify-center items-center w-full mt-auto">
                <p className="font-bold">
                  {fetchedEventDetail?.ownParticipantsData?.extraParticipants +
                    1}{' '}
                  {t('events.manyRegistered')}
                </p>
                <p className="my-1 px-3 w-[90%] flex justify-center">
                  {fetchedEventDetail?.ownParticipantsData?.message ?? ''}{' '}
                </p>
                <p
                  className="font-semibold  text-blue-600 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleOpenPopup()
                  }}
                >
                  {t('blog.edit')}
                </p>
              </div>
            )
          }
        } else if (fetchedEventDetail?.bookingStatus === 'not_attending') {
          content = (
            <div className="flex flex-col justify-center items-center w-full mt-auto">
              <p className="font-semibold">{t('events.youDeclined')}</p>
              <p className="font-semibold my-1 px-3 w-[90%] flex justify-center">
                {fetchedEventDetail?.ownParticipantsData?.message ?? ''}{' '}
              </p>
              <p
                className="font-semibold text-blue-600 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation()
                  handleOpenPopup()
                }}
              >
                {t('blog.edit')}
              </p>
            </div>
          )
        }
      }

      return <>{content}</>
    } */

    /* const displayAttachmentsContainer = (
      fetchedEventDetail?: EventListItemData,
    ) => {
      return (
        <div
          className={`flex  justify-start overflow-x-auto gap-x-1 w-full min-h-[83px] items-center pl-7 mb-3`}
        >
          {fetchedEventDetail?.files?.map((file) => (
            <div id="attachments-content" className="flex " key={file.id}>
              {(file.mime_type === 'image/jpeg' ||
                file.mime_type === 'image/png') && (
                <img
                  src={file.file}
                  alt=""
                  className={`flex flex-row rounded-md object-cover h-16 w-16 mx-1 cursor-pointer shadow-sm`}
                  onClick={(e) => {
                    e.stopPropagation()
                    window.open(file.file, '_blank')
                  }}
                />
              )}
              {file.mime_type === 'audio/mpeg' && (
                <div
                  className={`flex flex-col border-2 justify-center mx-1 items-center rounded-md h-16 w-16 border-blue-300 cursor-pointer shadow-sm`}
                  onClick={(e) => {
                    e.stopPropagation()
                    handlePlayAudio(file)
                  }}
                >
                  <FontAwesomeIcon
                    icon={faWaveform}
                    className="w-full text-sm text-blue-500"
                  />
                  <p className="text-sm">
                    {moment.utc(file.metadata.durationInMs).format('m:ss')}
                  </p>
                </div>
              )}
              {file.mime_type === 'application/pdf' && (
                <div
                  className={`flex flex-col border-2 justify-center items-center rounded-md h-16 w-16 mx-1  border-red-300 cursor-pointer text-red-500 shadow-sm p-1`}
                  onClick={(e) => {
                    e.stopPropagation()
                    window.open(file.file, '_blank')
                  }}
                >
                  <FontAwesomeIcon icon={faFilePdf} size="xl" />
                  <p className="text-2xs text-center w-[95%] break-words line-clamp-2">
                    {file.name}
                  </p>
                </div>
              )}
            </div>
          ))}
          {fetchedEventDetail?.location && (
            <div
              id="location-container"
              className={`rounded-md flex flex-col w-auto h-16 ml-2 text-xs bg-white justify-center p-2 border-2 border-greenForrest`}
            >
              <div className="flex justify-start items-center gap-2 p-2 whitespace-nowrap">
                <p className="flex flex-col font-semibold text-gray-700 w-full">
                  <div className="flex">
                    <FontAwesomeIcon
                      icon={faMapPin}
                      size="sm"
                      className="text-blueDark pr-1"
                    />
                    {fetchedEventDetail?.location?.name}
                  </div>
                  {locationType === 'address' ? (
                    <>
                      <div>{fetchedEventDetail?.location?.address}</div>
                      <div>
                        {fetchedEventDetail?.location?.zipcode}{' '}
                        {fetchedEventDetail?.location?.city}
                      </div>
                    </>
                  ) : locationType === 'coord' ? (
                    <div>
                      {fetchedEventDetail?.location?.lat}
                      {'°, '}
                      {fetchedEventDetail?.location?.long}
                      {'°'}
                    </div>
                  ) : (
                    <></>
                  )}
                </p>
                {fetchedEventDetail?.location?.type !== 'freetext' ? (
                  <div className="flex justify-end w-[80%] pl-2">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      size="lg"
                      className="text-[#6BBD73]"
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}
          <div className="flex justify-between p-2  items-center my-2"></div>
        </div>
      )
    } */

    return (
      <>
        {!isOverviewVisible ? (
          <div
            className={`flex flex-col max-h-[400px] w-full rounded-3xl mr-1 overflow-y-auto justify-between  scrollbar-hide overflow-visible relative ${
              isPopupVisible ? 'filter blur-sm' : ''
            }`}
          >
            {/****************************** HEADER & content  div1 ******************************************* */}
            <div onClick={() => handleExpaneded()}>
              {fetchedEventDetail && (
                <EventExpendableCardHeader
                  event={fetchedEventDetail}
                  setFetchedEventDetail={setFetchedEventDetail}
                  fetchedEventOverview={fetchedEventOverview}
                  setLoading={setLoading}
                  setPopupVisible={setPopupVisible}
                  isOverviewVisible={isOverviewVisible}
                  setOverviewVisible={setOverviewVisible}
                  openSetting={openSttings}
                  onOpenSetting={() => setOpenSettings(!openSttings)}
                  isExpanded={isExpanded}
                  triggerHasResponded={triggerHasResponded}
                />
              )}
              {openSttings && (
                <SettingsDetailView
                  setOpenSettings={setOpenSettings}
                  openSttings
                  fetchedEventDetail={fetchedEventDetail}
                  handleExpaneded={handleExpaneded}
                />
              )}
              {/****************************** END OF HEADER ******************************************* */}
              {bookingType && (
                <h3 className="px-4 font-bold mb-2">
                  {fetchedEventDetail?.topic}
                </h3>
              )}

              {bookingType && fetchedEventDetail?.presentGuardians && (
                <div className="flex flex-col px-4 text-sm mb-2">
                  <div className="font-bold">{t('events.present')}</div>
                  {fetchedEventDetail.presentGuardians
                    ?.map((guardian) => {
                      return guardian.title
                    })
                    .join(', ')}
                </div>
              )}
              {/****************************** Start OF Settings faEllipsisV ******************************************* */}
            </div>
            {
              <div className="flex flex-col mt-2 justify-start animate-fadeIn">
                {/*********************************** Attachments (all events) container ************************************** */}
                {/* {displayAttachmentsContainer(fetchedEventDetail)} */}

                {/********************************* Post av info **************************************** */}

                {/************************************* Makw a resppnse yes/no for req a res events ************************************ */}
              </div>
            }
          </div>
        ) : fetchedEventDetail?.statusSummary ? (
          <EventReqResOverview
            eventDetail={fetchedEventDetail}
            setOverviewVisible={setOverviewVisible}
            isOverviewVisible={isOverviewVisible}
            eventOverview={fetchedEventOverview}
            refreshDetail={() => triggerHasResponded?.()}
            hasResponded={hasResponded}
            openHelpSection={openHelpSection}
            setOpenHelpSection={setOpenHelpSection}
          />
        ) : (
          <EventOverview
            eventDetail={fetchedEventDetail}
            setOverviewVisible={setOverviewVisible}
            isOverviewVisible={isOverviewVisible}
            eventOverview={fetchedEventOverview}
            refreshDetail={() => triggerHasResponded?.()}
            openHelpSection={openHelpSection}
            setOpenHelpSection={setOpenHelpSection}
          />
        )}
        <div className="flex items-center justify-center">
          {
            <EventParticipatingPopUp
              eventDetail={fetchedEventDetail}
              onClose={handleClosePopup}
              isOpen={isPopupVisible}
              refreshDetail={() => triggerHasResponded?.()}
            />
          }
        </div>
      </>
    )
  },
)
