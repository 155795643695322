import React from 'react'
import moment, { Moment } from 'moment'
import { useTranslation } from 'react-i18next'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faCheck, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { AnimatePresence, motion } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  onOpen: () => void
  onClick: (e: string[] | undefined) => void
  overwriteSelectAll?: () => void
  isOpen: boolean
  groups?: { label: string; id: string }[]
  items: {
    label: string
    id: string
    icon?: IconDefinition
    color?: string
    groupId?: string
  }[]
  selectedIds?: string[]
  header: string
  description?: string
  type: 'list' | 'slider'
  singular?: boolean
}

const containerVariants = {
  hidden: { height: 0, opacity: 0 },
  visible: {
    height: 'auto',
    opacity: 1,
    transition: {
      type: 'tween',
      //duration: 0.5,
      //when: 'beforeChildren', // Ensure container animates before children
      staggerChildren: 0.03, // Stagger children animations
    },
  },
  exit: { height: 0, opacity: 0, transition: { duration: 0.3 } },
}

const itemVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
}

const ListviewSelections: React.FC<Props> = ({
  onOpen,
  onClick,
  overwriteSelectAll,
  isOpen,
  groups,
  items,
  selectedIds,
  header,
  description,
  type,
  singular,
}) => {
  const { t } = useTranslation()

  if (type === 'list') {
    return (
      <div className="p-4">
        <div
          className="flex w-full justify-between font-semibold cursor-pointer text-sm "
          onClick={() => onOpen()}
        >
          <div>{header}</div>
          {/* Rotating Chevron */}
          <motion.div
            animate={{ rotate: isOpen ? 180 : 0 }} // Rotate 180 degrees when open
            transition={{
              type: 'spring',
              stiffness: 300,
              damping: 20,
            }} // Smooth spring animation
          >
            <FontAwesomeIcon icon={faChevronDown} />
          </motion.div>
        </div>

        {/* Animate Presence for height animation */}
        <AnimatePresence>
          {isOpen && (
            <motion.div
              className="flex w-full gap-y-2 flex-col text-xs p-2"
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              {/* Select All */}
              {!singular && (
                <motion.div
                  className="flex w-full justify-between"
                  onClick={() => {
                    if (overwriteSelectAll) {
                      overwriteSelectAll()
                    } else {
                      if (selectedIds?.length === items?.length) {
                        onClick([])
                      } else {
                        onClick(items?.map((item) => item.id))
                      }
                    }
                  }}
                  variants={itemVariants}
                >
                  <div className="font-semibold">
                    {t('multiselect.selectAll')}
                  </div>
                  <div
                    className={`w-5 h-5 rounded-full border flex justify-center items-center ${
                      !selectedIds || selectedIds.length === items?.length
                        ? 'border-transparent bg-greenSelected'
                        : 'border-gray-400'
                    }`}
                  >
                    {(!selectedIds || selectedIds.length === items?.length) && (
                      <FontAwesomeIcon
                        icon={faCheck}
                        color="#ffffff"
                        className="text-xs"
                      />
                    )}
                  </div>
                </motion.div>
              )}

              {groups && groups.length ? (
                groups.map((group) => {
                  return (
                    <>
                      <motion.div
                        className="flex w-full justify-between font-semibold text-sm"
                        key={group.id}
                        variants={itemVariants}
                      >
                        {group.label}
                      </motion.div>
                      {items
                        ?.filter((item) => item.groupId === group.id)
                        ?.map((item) => {
                          const isChecked: boolean =
                            !selectedIds ||
                            selectedIds.find((sID) => sID === item.id)
                              ? true
                              : false

                          return (
                            <Item
                              onClick={() => {
                                if (singular) {
                                  onClick([item.id])
                                } else {
                                  if (isChecked) {
                                    if (!selectedIds) {
                                      onClick([item.id])
                                    } else {
                                      onClick(
                                        selectedIds?.filter(
                                          (sID) => sID !== item.id,
                                        ),
                                      )
                                    }
                                  } else {
                                    onClick([...(selectedIds || []), item.id])
                                  }
                                }
                              }}
                              isChecked={isChecked}
                              id={item.id}
                              label={item.label}
                              color={item.color}
                              icon={item.icon}
                            />
                          )
                        })}
                    </>
                  )
                })
              ) : (
                <>
                  {/* Department Items */}
                  {items?.map((item) => {
                    const isChecked: boolean =
                      !selectedIds || selectedIds.find((sID) => sID === item.id)
                        ? true
                        : false

                    return (
                      <Item
                        onClick={() => {
                          if (singular) {
                            onClick([item.id])
                          } else {
                            if (isChecked) {
                              if (!selectedIds) {
                                onClick([item.id])
                              } else {
                                onClick(
                                  selectedIds?.filter((sID) => sID !== item.id),
                                )
                              }
                            } else {
                              onClick([...(selectedIds || []), item.id])
                            }
                          }
                        }}
                        isChecked={isChecked}
                        id={item.id}
                        label={item.label}
                        color={item.color}
                        icon={item.icon}
                      />
                    )
                  })}
                </>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    )
  }
  if (type === 'slider') {
    return <>Slider</>
  }

  return <></>
}

export default ListviewSelections

interface ItemProps {
  onClick: () => void
  isChecked: boolean
  id: string
  label: string
  color?: string
  icon?: IconDefinition
}

export const Item: React.FC<ItemProps> = ({
  onClick,
  isChecked,
  id,
  label,
  color,
  icon,
}) => {
  return (
    <motion.div
      className="flex w-full justify-between cursor-pointer"
      key={id}
      onClick={() => onClick()}
      variants={itemVariants}
    >
      {icon ? (
        <div className="flex flex-row items-center gap-x-2">
          <FontAwesomeIcon
            className="w-3 h-3"
            style={{ color: color }}
            icon={icon}
            flip="horizontal"
          />
          <div>{label}</div>
        </div>
      ) : (
        <div>{label}</div>
      )}

      <div
        className={`w-5 h-5 rounded-full border flex justify-center items-center ${
          isChecked ? 'border-transparent bg-blueDark' : 'border-gray-400'
        }`}
        style={
          isChecked
            ? {
                backgroundColor: color ? color : '#1398F7',
              }
            : {}
        }
      >
        {isChecked && (
          <FontAwesomeIcon icon={faCheck} color="#ffffff" className="text-xs" />
        )}
      </div>
    </motion.div>
  )
}
