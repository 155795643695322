import React, { useContext, useState } from 'react'
import classNames from 'classnames'
import ModalRegular from '../../../../components/ModalRegular'
import { Slider } from '../../../../components/Slider'
import { Button } from '../../../../components/Button'
import Swal from 'sweetalert2'
import { t } from 'i18next'
import { UserRole } from '../../../../Models/UserRole'
import { useParams } from 'react-router-dom'
import { AdminStoreContext } from '../../../../store/adminStore'
import {
  getImageFromUser,
  getUserNameFromRole,
} from '../../../../utils/parseUtils'
import KidCard from './KidCard'

interface InviteVHModalProps {
  imageUrl?: string
  titleContainerClassName?: string
  title?: React.ReactNode
  onClose: () => void
  isOpen: boolean
  userRole: UserRole
  allUserRolesForUser: UserRole[]
  type: 'paused' | 'active' | 'waiting' | 'invited'
}

const VHModal: React.FC<InviteVHModalProps> = ({
  imageUrl,
  titleContainerClassName,
  title,
  isOpen,
  onClose,
  userRole,
  allUserRolesForUser,
  type,
}) => {
  const {
    setUploadParent,
    pauseUserRole,
    fetchActiveUserRoles,
    activateUserRoles,
    fetchPendingUserRoles,
    cancelUserRole,
    fetchNewUserRoles,
  } = useContext(AdminStoreContext)
  const [fakeState, setFakeState] = useState(false)
  const { schoolId } = useParams()
  return (
    <ModalRegular
      isOpen={isOpen}
      onClose={onClose}
      title={t('general.guardian')}
      bgColor="bg-blue-400 p-4"
      closeColor="white"
    >
      <div className="flex flex-wrap flex-col p-6 gap-y-6 bg-gray-50">
        <div className="flex relative flex-row items-center ml-2 ">
          <div className="flex flex-row w-full items-center">
            <label className="relative flex items-center justify-center border rounded-full max-w-[60px] bg-gray-300  max-h-[60px]  min-w-[60px]  min-h-[60px] overflow-hidden">
              <img
                className={'w-[60px] h-[60px] object-cover rounded-full'}
                src={getImageFromUser(userRole.user)}
                alt=""
              />
            </label>
            <div className="flex flex-col w-full justify-center pl-2">
              <h1 className="font-bold">
                {type === 'invited'
                  ? userRole.invite_note
                  : userRole.user?.firstName + ' ' + userRole.user?.lastName}
              </h1>
              <h2 className="font-thin text-gray-600 text-sm">
                {getUserNameFromRole(userRole)}
              </h2>
              <h3 className="font-thin text-gray-600 text-sm">
                {userRole.user?.user_extra.attributes.street_address}
              </h3>
              <h4 className="font-thin text-gray-600 text-sm">
                {userRole.user?.user_extra.attributes.zip_code}{' '}
                {userRole.user?.user_extra.attributes.city}
              </h4>
            </div>
          </div>
          <div
            className={classNames(
              'flex flex-wrap justify-center w-full',
              titleContainerClassName,
            )}
          >
            {title}
          </div>
        </div>
        <div className="flex flex-row justify-between items-center">
          <>{t('admin.addDocSchool')}</>{' '}
          <Slider
            id={0}
            value={userRole.is_upload_document_parent}
            onSliderClick={function (id: number): void {
              setUploadParent(
                userRole.is_upload_document_parent ? false : true,
                allUserRolesForUser,
              ).finally(() => {
                setFakeState(!fakeState)
              })
            }}
            onBoxClick={function (id: number): void {
              /*setUploadParent(
                userRole.is_upload_document_parent ? false : true,
                userRole,
              )*/
            }}
          />
        </div>
        <div className="flex flex-col justify-center items-center">
          {allUserRolesForUser.map((uR) => {
            if (!uR.kid) {
              return null
            }
            return (
              <KidCard
                relation={uR.tyraRelation}
                title={uR.kid?.firstName + ' ' + uR.kid?.lastName}
                userRole={uR}
                smallPic={false}
              />
            )
          })}
        </div>
        {type === 'invited' && (
          <div className="flex w-full gap-x-2 justify-center">
            <Button
              variant="fullRed"
              label={t('admin.cancelInvite')}
              onClick={() => {
                Swal.fire({
                  text: t('admin.cancelInviteParentWarningText'),
                  icon: 'warning',
                  showCancelButton: false,
                  focusConfirm: false,
                  confirmButtonColor: '#7eb5f4',
                  cancelButtonColor: '#d33',
                  confirmButtonText: t('general.ok'),
                }).then((result) => {
                  if (result.isConfirmed) {
                    cancelUserRole([userRole]).finally(() => {
                      if (schoolId) {
                        fetchNewUserRoles(schoolId).finally(() => {
                          onClose()
                        })
                      }
                    })
                  }
                })
              }}
            />
          </div>
        )}
        {type === 'waiting' && (
          <div className="flex w-full gap-x-2 justify-center">
            <Button
              variant="default"
              label={t('admin.acceptInvite')}
              onClick={() => {
                Swal.fire({
                  text: t('admin.accept'),
                  icon: 'warning',
                  showCancelButton: false,
                  focusConfirm: false,
                  confirmButtonColor: '#7eb5f4',
                  cancelButtonColor: '#d33',
                  confirmButtonText: t('general.ok'),
                }).then((result) => {
                  if (result.isConfirmed) {
                    activateUserRoles(allUserRolesForUser).finally(() => {
                      if (schoolId) {
                        fetchPendingUserRoles().finally(() => {
                          onClose()
                        })
                      }
                    })
                  }
                })
              }}
            />
            <Button
              variant="fullRed"
              label={t('admin.cancelInvite')}
              onClick={() => {
                Swal.fire({
                  text: t('admin.cancelInvite'),
                  icon: 'warning',
                  showCancelButton: false,
                  focusConfirm: false,
                  confirmButtonColor: '#7eb5f4',
                  cancelButtonColor: '#d33',
                  confirmButtonText: t('general.ok'),
                }).then((result) => {
                  if (result.isConfirmed) {
                    cancelUserRole([userRole]).finally(() => {
                      if (schoolId) {
                        fetchPendingUserRoles().finally(() => {
                          onClose()
                        })
                      }
                    })
                  }
                })
              }}
            />
          </div>
        )}
        {type === 'active' && (
          <div className="w-full flex justify-center">
            <div className="flex flex-col w-fit items-center gap-y-2">
              <div className="flex w-full">
                <Button
                  className="flex-grow"
                  label={t('admin.pause')}
                  variant="fullYellow"
                  onClick={() => {
                    Swal.fire({
                      text: t('admin.pausedUserText'),
                      icon: 'warning',
                      showCancelButton: true,
                      focusConfirm: false,
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                      confirmButtonText: t('general.ok'),
                      cancelButtonText: t('general.cancel'),
                    }).then((result) => {
                      if (result.isConfirmed) {
                        pauseUserRole(allUserRolesForUser).finally(() => {
                          if (schoolId) {
                            fetchActiveUserRoles(schoolId).finally(() => {
                              onClose()
                            })
                          }
                        })
                      }
                    })
                  }}
                />
              </div>
              <div>
                <Button
                  className="flex-grow"
                  label={t('documents.remove')}
                  variant="fullRed"
                  onClick={() => {
                    if (allUserRolesForUser.filter((uR) => uR.kid).length > 1) {
                      Swal.fire({
                        icon: 'warning',
                        text: t('admin.removeActiveMoreKids').replace(
                          'XYC1337',
                          userRole.user?.firstName +
                            ' ' +
                            userRole.user?.lastName,
                        ),
                        showCancelButton: true,
                        focusConfirm: false,
                        confirmButtonColor: '#7eb5f4',
                        cancelButtonColor: '#d33',
                        confirmButtonText: t('general.ok'),
                      }).then((result) => {
                        if (result.isConfirmed) {
                          cancelUserRole([userRole]).finally(() => {
                            if (schoolId) {
                              fetchActiveUserRoles(schoolId).finally(() => {
                                onClose()
                              })
                            }
                          })
                        }
                      })
                    } else {
                      Swal.fire({
                        text: t('admin.removeActiveOneKid')
                          .replace(
                            'XYC1337',
                            userRole.user?.firstName +
                              ' ' +
                              userRole.user?.lastName,
                          )
                          .replace('CYX7331', userRole.kid?.attributes.kidName),
                        icon: 'warning',
                        showCancelButton: true,
                        focusConfirm: false,
                        confirmButtonColor: '#7eb5f4',
                        cancelButtonColor: '#d33',
                        confirmButtonText: t('general.ok'),
                      }).then((result) => {
                        if (result.isConfirmed) {
                          cancelUserRole([userRole]).finally(() => {
                            if (schoolId) {
                              fetchActiveUserRoles(schoolId).finally(() => {
                                onClose()
                              })
                            }
                          })
                        }
                      })
                    }
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </ModalRegular>
  )
}

export default VHModal
