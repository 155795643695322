import React, { useContext, useEffect } from 'react'
import Page from '../../components/Page'
import ColorCard from '../../components/ColorCard'
import { MyConnectionsCard } from './components/MyConnectionsCard'
import { NavigationStoreContext } from '../../store/navigationStore'
import { t } from 'i18next'
import { UserStoreContext } from '../../store/userStore'
import { useParams } from 'react-router-dom'
import { AdminStoreContext } from '../../store/adminStore'
import { UserRole } from '../../Models/UserRole'
import { observer } from 'mobx-react'
import { MyConnectionsCardKid } from './components/MyConnectionCardKid'
import Swal from 'sweetalert2'
import { translateRelation } from '../admin/inviteHandleUsers/components/KidCard'

// Improvement: Move the swal functions to a separate swal file.
export const errorSwal = () => {
  Swal.fire({
    text: t('events.eventAlarmFail') + '😳',
    icon: 'error',
    showCancelButton: false,
    focusConfirm: false,
    showConfirmButton: false,
    confirmButtonColor: '#7eb5f4',
    cancelButtonColor: '#d33',
    //confirmButtonText: t('general.ok'),
    toast: true,
    timer: 5000,
    timerProgressBar: true,
    position: 'bottom',
  })
}
export const successSwal = (
  successMsg: string = t('documents.sharedSuccessTitle') + '👍',
) => {
  Swal.fire({
    text: successMsg,
    icon: 'success',
    showCancelButton: false,
    focusConfirm: false,
    showConfirmButton: false,
    confirmButtonColor: '#7eb5f4',
    cancelButtonColor: '#d33',
    //confirmButtonText: t('general.ok'),
    toast: true,
    timer: 3000,
    timerProgressBar: true,
    position: 'bottom',
  })
}
export const warningSwal = (warningMsg: string) => {
  Swal.fire({
    text: t('swal.' + warningMsg),
    icon: 'warning',
    showCancelButton: false,
    focusConfirm: false,
    showConfirmButton: false,
    confirmButtonColor: '#7eb5f4',
    cancelButtonColor: '#d33',
    //confirmButtonText: t('general.ok'),
    toast: true,
    timer: 3000,
    timerProgressBar: true,
    position: 'bottom',
    background: '#fffd8d',
  })
}

export const MyConnections: React.FC<{ userRole?: UserRole }> = observer(() => {
  const { setTitle } = useContext(NavigationStoreContext)
  useEffect(() => {
    setTitle(t('settings.myConnections'))
  })
  const { schoolId } = useParams()
  const {
    fetchMyActiveUserRoles,
    myActiveUserRoles,
    fetchMyPendingUserRoles,
    myPendingUserRoles,
    fetchMyNewUserRoles,
    myNewUserRoles,
    fetchMyPausedUserRoles,
    myPausedUserRoles,
  } = useContext(UserStoreContext)
  const { setWaitingUserRole, removeUserRole } = useContext(AdminStoreContext)
  useEffect(() => {
    if (schoolId) {
      fetchMyActiveUserRoles(schoolId)
      fetchMyPendingUserRoles()
      fetchMyNewUserRoles(schoolId)
      fetchMyPausedUserRoles()
    }
  }, [schoolId])

  return (
    <Page naked topBar={''}>
      {myNewUserRoles && myNewUserRoles.length > 0 && (
        <ColorCard title={t('settings.newInvitation')} bgColor="bg-eventsMain">
          <div className="flex justify-center flex-wrap gap-4">
            {myNewUserRoles.map((role, index) => {
              const getFullName = (kid: any) => {
                const firstName = kid?.attributes?.firstName
                const lastName = kid?.attributes?.lastName
                return firstName && lastName ? `${firstName} ${lastName}` : ''
              }
              if (role.kid) {
                return (
                  <MyConnectionsCardKid
                    key={index}
                    fullName={getFullName(role.kid)}
                    active={false}
                    userRole={role}
                    showApprove
                    showRemove
                    showRelation
                    cancelAction={() => {
                      removeUserRole(role)
                        .then(() => {
                          successSwal()
                        })
                        .catch(() => {
                          errorSwal()
                          throw new Error()
                        })
                        .finally(() => {
                          if (schoolId) {
                            fetchMyPendingUserRoles()
                            fetchMyNewUserRoles(schoolId)
                          }
                        })
                    }}
                    approveAction={() => {
                      if (role) {
                        if (role.tyraRelation) {
                          setWaitingUserRole(role)
                            .then(() => {
                              successSwal()
                            })
                            .catch(() => {
                              errorSwal()
                              throw new Error()
                            })
                            .finally(() => {
                              if (schoolId) {
                                fetchMyPendingUserRoles()
                                fetchMyNewUserRoles(schoolId)
                              }
                            })
                        } else {
                          errorSwal()
                        }
                      }
                    }}
                  />
                )
              } else {
                return (
                  <MyConnectionsCard
                    key={index}
                    showApprove
                    showRemove
                    showHideInSchedule
                    showDepartment
                    userRole={role}
                    cancelAction={() => {
                      removeUserRole(role)
                        .then(() => {
                          successSwal()
                        })
                        .catch(() => {
                          errorSwal()
                          throw new Error()
                        })
                        .finally(() => {
                          if (schoolId) {
                            fetchMyPendingUserRoles()
                            fetchMyNewUserRoles(schoolId)
                          }
                        })
                    }}
                    approveAction={() => {
                      if (role) {
                        setWaitingUserRole(role)
                          .then(() => {
                            successSwal()
                          })
                          .catch(() => {
                            errorSwal()
                            throw new Error()
                          })
                          .finally(() => {
                            if (schoolId) {
                              fetchMyPendingUserRoles()
                              fetchMyNewUserRoles(schoolId)
                            }
                          })
                      }
                    }}
                  />
                )
              }
            })}
          </div>
        </ColorCard>
      )}

      {myPendingUserRoles && myPendingUserRoles.length > 0 && (
        <ColorCard title={t('settings.waitingApproval')} bgColor="bg-[#EBDB76]">
          <div className="flex justify-center flex-wrap gap-4">
            {myPendingUserRoles?.map((role, index) => {
              const getFullName = (user: any) => {
                if (user && user.firstName && user.lastName) {
                  return `${user.firstName} ${user.lastName}`
                }
                return ''
              }
              if (role.kid) {
                return (
                  <MyConnectionsCardKid
                    key={index}
                    fullName={getFullName(role.kid)}
                    active={true}
                    userRole={role}
                    showRelation
                    saveDirectly
                  />
                )
              } else {
                return (
                  <MyConnectionsCard
                    userRole={role}
                    key={index}
                    showHideInSchedule
                    showDepartment
                    saveDirectly
                  />
                )
              }
            })}
          </div>
        </ColorCard>
      )}

      {myActiveUserRoles && myActiveUserRoles.length > 0 && (
        <ColorCard title={t('settings.active')} bgColor="bg-[#BAE391]">
          <div className="flex justify-center flex-wrap gap-4">
            {myActiveUserRoles.map((role, index) => {
              const getFullName = (kid: any) => {
                return kid && kid.firstName && kid.lastName
                  ? `${kid.firstName} ${kid.lastName}`
                  : ''
              }
              if (role.kid) {
                return (
                  <MyConnectionsCardKid
                    key={index}
                    fullName={getFullName(role.kid)}
                    active={true}
                    userRole={role}
                    showRemove
                    showRelation
                    saveDirectly
                    cancelAction={() => {
                      removeUserRole(role)
                        .then(() => {
                          successSwal()
                        })
                        .catch(() => {
                          errorSwal()
                          throw new Error()
                        })
                        .finally(() => {
                          if (schoolId) {
                            fetchMyActiveUserRoles(schoolId)
                          }
                        })
                    }}
                  />
                )
              } else {
                return (
                  <MyConnectionsCard
                    key={index}
                    showRemove
                    showHideInSchedule
                    userRole={role}
                    showDepartment
                    saveDirectly
                    cancelAction={() => {
                      removeUserRole(role)
                        .then(() => {
                          successSwal()
                        })
                        .catch(() => {
                          errorSwal()
                          throw new Error()
                        })
                        .finally(() => {
                          if (schoolId) {
                            fetchMyActiveUserRoles(schoolId)
                          }
                        })
                    }}
                  />
                )
              }
            })}
          </div>
        </ColorCard>
      )}

      {myPausedUserRoles && myPausedUserRoles.length > 0 && (
        <ColorCard title={t('settings.paused')} bgColor="bg-orange-400">
          <div className="flex justify-center flex-wrap gap-4">
            {myPausedUserRoles?.map((role, index) => {
              const getFullName = (user: any) => {
                if (user && user.firstName && user.lastName) {
                  return `${user.firstName} ${user.lastName}`
                }
                return ''
              }
              if (role.kid) {
                return (
                  <MyConnectionsCardKid
                    key={index}
                    fullName={
                      translateRelation(role.tyraRelation) +
                      ' ' +
                      t('settings.to') +
                      ' ' +
                      getFullName(role.kid)
                    }
                    active={true}
                    userRole={role}
                    showRemove
                    cancelAction={() => {
                      removeUserRole(role)
                        .then(() => {
                          successSwal()
                        })
                        .catch(() => {
                          errorSwal()
                          throw new Error()
                        })
                        .finally(() => {
                          if (schoolId) {
                            fetchMyPausedUserRoles()
                          }
                        })
                    }}
                  />
                )
              } else {
                return (
                  <MyConnectionsCard
                    key={index}
                    userRole={role}
                    showRemove
                    cancelAction={() => {
                      removeUserRole(role)
                        .then(() => {
                          successSwal()
                        })
                        .catch(() => {
                          errorSwal()
                          throw new Error()
                        })
                        .finally(() => {
                          if (schoolId) {
                            fetchMyPausedUserRoles()
                          }
                        })
                    }}
                  />
                )
              }
            })}
          </div>
        </ColorCard>
      )}
    </Page>
  )
})
