import React from 'react'
import { userStore } from './userStore'
import {
  FILE_JOB_STATUS,
  FileJob,
  RequestBlogPortfolioObject,
} from '../Models/FileJob'
import { makeAutoObservable } from 'mobx'
import Parse from 'parse'
import FileSaver from 'file-saver'
import JsZip from 'jszip'
import { BloggDoc } from '../Models/BloggDoc'
import { Kid } from '../Models/Kid'
import moment from 'moment'
import { t } from 'i18next'
import { EVENT3_TYPE, IsoDate } from './event3Store'

export interface ExportResponse {
  fileJobObjectId: string
  status: FILE_JOB_STATUS
}

interface RequestEventListParams {
  schoolId: string
  departmentIds?: string[]
  typeOfEvent: EVENT3_TYPE[]
  fromDate: IsoDate
  toDate: IsoDate
  userRoleId: string
  languageCode: string
  eventVisibility?: StaffEventsRead
  filterTagIds?: string[] | undefined
}

export enum StaffEventsRead {
  NONE_STAFF_EVENTS = 'none_staff_events',
  ONLY_STAFF_EVENTS = 'only_staff_events',
  ALL_EVENTS = 'all_events',
}

class FileJobStore {
  fileJobs?: FileJob[]

  constructor() {
    makeAutoObservable(this)
  }
  fetchFileJobs = async () => {
    const currentUser = userStore.currentUser
    const fileJobQuery = new Parse.Query(FileJob)
      //.equalTo('ttl', XXX) VI GÖR DENNA SEN
      .equalTo('user', currentUser)
    const fileJobs = await fileJobQuery.find()
    this.setFileJobs(fileJobs)
  }
  setFileJobs = (fileJobs: FileJob[]) => {
    this.fileJobs = fileJobs
  }

  exportEventList = async (values: RequestEventListParams) => {
    try {
      const exportResponse: ExportResponse | undefined = await Parse.Cloud.run(
        'exportEventList',
        values,
      )

      return exportResponse
    } catch (error) {
      return null
    }
  }

  generatePdf = async (values: RequestBlogPortfolioObject) => {
    try {
      const exportResponse: ExportResponse | undefined = await Parse.Cloud.run(
        'exportBlogPortfolio',
        values,
      )

      return exportResponse
    } catch (error) {
      return null
    }
  }

  setStatusToOpenedInFileJob = async (fileJob: FileJob) => {
    const fileJobId = fileJob.id

    const setStatusOpened = await Parse.Cloud.run(
      'setStatusToOpenedInFileJob',
      {
        fileJobObjectId: fileJobId,
      },
    )
  }

  enableProclaimInFileJob = async (fileJobId: string) => {
    const setStatusOpened = await Parse.Cloud.run('enableProclaimInFileJob', {
      fileJobObjectId: fileJobId,
    })
  }

  fetchCurrentFileJob = async (fileJobId: string) => {
    const fileJobQuery = new Parse.Query(FileJob)
    fileJobQuery.equalTo('objectId', fileJobId)
    fileJobQuery.equalTo('status', FILE_JOB_STATUS.SUCCESS)

    const fileJob = await fileJobQuery.first()

    return fileJob
  }

  downloadAndZip = async (
    bloggDocs: BloggDoc[],
    type: 'blogg' | 'portfolio',
    kidFirstName?: string,
  ) => {
    const zip = new JsZip()
    const schoolName = userStore.currentUserRole?.school_pointer.schoolName

    const folderName =
      type === 'blogg'
        ? t('menu.blog') + ' ' + schoolName
        : t('portfolio.kidsPortfolio').replace(
            'XXKIDSNAMEXX',
            kidFirstName ?? '',
          )
    const dates: string[] = []
    for (const bloggDoc of bloggDocs) {
      const urls = bloggDoc?.attributes?.images
        ?.filter(
          (item: any) =>
            !item?.attributes?.audio_file && !item?.attributes?.video_file,
        )
        ?.map((item: any) => item?.attributes?.image?._url)
      if (urls) {
        const promises = urls.map(async (url: string) => {
          const res = await fetch(url)
          const blob = await res.blob()
          return blob
        })
        const res = await Promise.all(promises)

        res.forEach((blob, index) => {
          let filename = ''
          if (
            dates.find(
              (date) =>
                date === moment(bloggDoc.createdAt).format('YYYY-MM-DD'),
            )
          ) {
            filename =
              moment(bloggDoc.createdAt).format('YYYY-MM-DD') +
              '-00' +
              dates.filter(
                (date) =>
                  date === moment(bloggDoc.createdAt).format('YYYY-MM-DD'),
              )?.length
          } else {
            filename = moment(bloggDoc.createdAt).format('YYYY-MM-DD') + '-000'
          }
          dates.push(moment(bloggDoc.createdAt).format('YYYY-MM-DD'))
          if (blob.type.includes('png')) {
            zip.file(`${filename}.png`, blob)
          } else if (blob.type.includes('jp')) {
            zip.file(`${filename}.jpeg`, blob)
          }
        })
      }
    }

    const zipFile = await zip.generateAsync({ type: 'blob' })

    this.downloadZip(zipFile, folderName)
  }

  downloadZip(file: any, folderName: string) {
    const a = document.createElement('a')
    a.download = `${folderName}.zip`

    const url = URL.createObjectURL(file)
    a.href = url
    a.style.display = 'none'
    document.body.appendChild(a)
    a.click()
    a.remove()

    URL.revokeObjectURL(url)
  }
}

export const fileJobStore = (() => {
  return new FileJobStore()
})()
export const FileJobStoreContext: React.Context<FileJobStore> =
  React.createContext(fileJobStore)
