import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useContext, useState } from 'react'
import { LoadingBar } from '../../../../components/LoadingBar'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { StatusBox } from '../invitedUsers/components/StatusBox'
import { Button } from '../../../../components/Button'
import Swal from 'sweetalert2'
import { t } from 'i18next'
import { UserRole } from '../../../../Models/UserRole'
import { USER_ROLE_TYPE } from '../../../../Enums/TyraEnums'
import { useParams } from 'react-router-dom'
import { AdminStoreContext } from '../../../../store/adminStore'
import {
  getFullNameFromUser,
  getImageFromRole,
  getUserNameFromRole,
} from '../../../../utils/parseUtils'

export const TeacherCard: React.FC<{
  children?: React.ReactNode
  description?: string
  title?: string
  loading?: boolean
  className?: string
  icon?: IconDefinition
  iconClassName?: string
  titleContainerClassName?: string
  onClick?: () => void
  activeState?: boolean
  userRole: UserRole
  type?: 'paused' | 'active' | 'waiting' | 'invited'
}> = ({
  children,
  description,
  title,
  loading,
  className,
  icon,
  iconClassName,
  titleContainerClassName,
  onClick,
  activeState,
  userRole,
  type,
}) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null)
  const [isActiveAdmin, setIsActiveAdmin] = useState(true)
  const [isActiveWrite, setIsActiveWrite] = useState(false)

  const { activateUserRoles, fetchPausedUserRoles } =
    useContext(AdminStoreContext)

  const { schoolId } = useParams()

  return (
    <div
      className={classNames(
        ' min-h-[60px] overflow-hidden rounded-2xl shadow-tyraShadow m-4 p-4  cursor-pointer',
      )}
      onClick={onClick}
    >
      {loading && <LoadingBar />}
      <div className=" rounded-2xl flex space-x-4 rounded-b-2xl items-center font-bold font-sans bg-white text-neutral-600 relative">
        <div
          className={classNames(
            'flex flex-wrap py-3 font-medium justify-start w-full h-full',
            className,
            { 'bg-transparent text-gray-500': activeState },
          )}
        >
          <>
            <div className="flex flex-row w-full ml-2">
              <label className="relative flex items-center justify-center border rounded-full max-w-[60px] bg-gray-300  max-h-[60px]  min-w-[60px]  min-h-[60px] overflow-hidden">
                <img
                  className={'w-[60px] h-[60px] object-cover rounded-full'}
                  src={getImageFromRole(userRole)}
                  alt=""
                />
              </label>
              <div className="flex flex-col w-full justify-center pl-2">
                <h1 className="font-bold">
                  {type === 'invited'
                    ? userRole.invite_note
                    : getFullNameFromUser(userRole.user)}
                </h1>
                <h2 className="font-thin text-gray-600">
                  {/* TODO: Här ska visas olika beroende på om lista är aktiva, pausade etc. */}
                  {type === 'invited' && <>{getUserNameFromRole(userRole)}</>}
                  {type === 'waiting' && <>{getUserNameFromRole(userRole)}</>}
                  {type === 'active' && <>{t('documents.staff')}</>}
                </h2>
              </div>
            </div>
            <div
              className={classNames(
                'flex flex-wrap justify-center w-full',
                titleContainerClassName,
              )}
            >
              {title}
            </div>

            <div
              className={classNames(
                'flex flex-wrap justify-center w-full',
                titleContainerClassName,
              )}
            >
              {title}
            </div>

            <div className="flex flex-col  gap-2 p-2 w-4/5">
              <div className="flex flex-row gap-x-2">
                <StatusBox
                  className={classNames(
                    'w-1/3 ',
                    userRole.role_type >= USER_ROLE_TYPE.ADMIN
                      ? 'bg-green-500'
                      : '',
                  )}
                  isActive={
                    userRole.role_type >= USER_ROLE_TYPE.ADMIN ? true : false
                  }
                  label={t('admin.admin')}
                />
                <StatusBox
                  className={classNames(
                    'w-2/3 ',

                    userRole.role_type >= USER_ROLE_TYPE.ADMIN ||
                      !userRole.configuration?.events?.readOnly
                      ? 'bg-red-300'
                      : '',
                  )}
                  isActive={
                    userRole.role_type >= USER_ROLE_TYPE.ADMIN ||
                    !userRole.configuration?.events?.readOnly
                      ? true
                      : false
                  }
                  label={t('admin.writePermissions')}
                />
              </div>
              <div className="flex flex-row gap-x-2">
                <StatusBox
                  className={classNames(
                    'w-2/3 ',

                    userRole.staff_sensetive_info_bool ? 'bg-red-400' : '',
                  )}
                  isActive={userRole.staff_sensetive_info_bool ? true : false}
                  label={t('admin.sensetiveInfo')}
                />

                <StatusBox
                  className={classNames(
                    'w-1/3 ',

                    userRole.staff_schedule_responsable ? 'bg-orange-400' : '',
                  )}
                  isActive={userRole.staff_schedule_responsable ? true : false}
                  label={t('menu.scheme')}
                />
              </div>
              <div className="flex flex-row gap-x-2">
                <StatusBox
                  className={classNames(
                    'w-1/3 ',
                    userRole.role_type >= USER_ROLE_TYPE.ADMIN ||
                      userRole.stat_access
                      ? 'bg-lime-300'
                      : '',
                  )}
                  isActive={
                    userRole.role_type >= USER_ROLE_TYPE.ADMIN ||
                    userRole.stat_access
                      ? true
                      : false
                  }
                  label={t('menu.statistics')}
                />
                <StatusBox
                  className={classNames(
                    'w-2/3 ',
                    userRole.role_type >= USER_ROLE_TYPE.SUPER_ADMIN
                      ? 'bg-blue-400'
                      : '',
                  )}
                  isActive={
                    userRole.role_type >= USER_ROLE_TYPE.SUPER_ADMIN
                      ? true
                      : false
                  }
                  label={t('admin.modalsAddServices')}
                />
              </div>
              {type === 'active' && (
                <div className="absolute top-1/2 right-2 transform -translate-y-1/2">
                  <FontAwesomeIcon
                    className="text-gray-300"
                    icon={faChevronRight}
                  />
                </div>
              )}
              {type === 'invited' && (
                <div className="absolute top-1/2 right-2 transform -translate-y-1/2">
                  <FontAwesomeIcon
                    className="text-gray-300"
                    icon={faChevronRight}
                  />
                </div>
              )}

              {type === 'waiting' && (
                <div className="absolute top-1/2 right-2 transform -translate-y-1/2">
                  <FontAwesomeIcon
                    className="text-gray-300"
                    icon={faChevronRight}
                  />
                </div>
              )}
            </div>

            {type === 'paused' && (
              <div className=" flex flex-col gap-y-4 w-full lg:flex-row justify-center gap-x-4 mt-4">
                <Button
                  size="custom"
                  variant="fullBlue"
                  label={t('admin.activate')}
                  onClick={() =>
                    Swal.fire({
                      //title: 'Are you sure?',
                      text: t('admin.warningActivate'),
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                      confirmButtonText: t('general.yes'),
                      cancelButtonText: t('general.cancel'),
                    }).then((result) => {
                      if (result.isConfirmed) {
                        activateUserRoles([userRole] ?? []).finally(() => {
                          if (schoolId) {
                            fetchPausedUserRoles()
                          }
                        })
                      }
                    })
                  }
                />
                <Button
                  size="custom"
                  variant="fullRed"
                  label={t('documents.remove')}
                  onClick={() =>
                    Swal.fire({
                      //title: 'Are you sure?',
                      text: t('absence.noticAreYouSure'),
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                      confirmButtonText: t('general.yes'),
                      cancelButtonText: t('general.cancel'),
                    })
                  }
                />
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  )
}
