import React from 'react'
import { observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { t } from 'i18next'

export interface IEventComment {
  id: string
  name: string
  thumbnail?: string
  comment: string
  postTime: string
  editTime?: string
  isOwner: boolean
}

const calculateElapsedTime = (timestamp: any) => {
  const commentTime = moment(timestamp)
  const currentTime = moment()

  // Calculate the difference in milliseconds
  const diffInMillis = currentTime.diff(commentTime)

  // Create a duration object from the difference
  const duration = moment.duration(diffInMillis)

  // Extract individual components
  const years = duration.years()
  const months = duration.months()
  const days = duration.days()
  const hours = duration.hours()
  const minutes = duration.minutes()

  // Build the elapsed time string
  let elapsedTimeString = ''
  if (years > 0) {
    elapsedTimeString += `${years} ${
      years === 1 ? t('absence.year') : t('absence.years')
    } `
  }
  if (months > 0) {
    elapsedTimeString += `${months} ${
      months === 1 ? t('absence.month') : t('absence.months')
    } `
  }
  if (days > 0) {
    elapsedTimeString += `${days} ${
      days === 1 ? t('absence.absenceDays') : t('absence.absenceDays')
    } `
  }
  if (hours > 0) {
    elapsedTimeString += `${hours} ${
      hours === 1 ? t('absence.hour') : t('absence.hours')
    } `
  }
  if (minutes > 0) {
    elapsedTimeString += `${minutes} ${
      minutes === 1 ? t('absence.minute') : t('absence.minutes')
    }`
  }

  return elapsedTimeString.trim()
}

export const CommentDetailCard: React.FC<IEventComment> = observer(
  (comment) => {
    const elapsedTime = calculateElapsedTime(comment.postTime)

    return (
      <div className="flex flex-col p-1 rounded-xl  w-[90%] mx-auto mb-2 animate-fadeIn">
        <div className="flex">
          <div className="flex min-w-[95%] items-center justify-center">
            <img
              className={`rounded-full h-9 w-9 mr-2 object-cover w-1/7`}
              src={comment.thumbnail || '/images/placeholder-image.png'}
              alt=""
            />
            <div className="flex justify-start  items-center space-x-1 w-full">
              <div className="flex flex-col w-full">
                <div className="text-xs font-bold w-full flex  gap-x-4">
                  <p>{comment.name}</p>
                  <p className="text-2xs mr-2 text-gray120">{elapsedTime}</p>
                </div>
                <p className="text-sm pl- w-full">{comment.comment}</p>
              </div>
            </div>
          </div>
          {/* This will be used later dont remove */}
          {/* <div className="flex justify-end w-full">
            <FontAwesomeIcon
              icon={faEllipsisH}
              size="1x"
              className="flex items-end text-gray-500 pr-2"
            />
          </div> */}
        </div>
      </div>
    )
  },
)
