import React, {
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { Spinner } from '../../components/Spinner'
import Page from '../../components/Page'
import { useNavigate } from 'react-router-dom'
import { UserStoreContext } from '../../store/userStore'
import { NavigationStoreContext } from '../../store/navigationStore'
import { useTranslation } from 'react-i18next'
import { StatisticStoreContext } from '../../store/statisticStore'
import DatePicker, { registerLocale } from 'react-datepicker'
import sv from 'date-fns/locale/sv'
import en from 'date-fns/locale/en-GB'
import es from 'date-fns/locale/es'
import moment from 'moment'
import { HeaderDays } from './components/HeaderDays'
import { CheckInOutBlock } from './components/CheckInOutBlock'
import { ScheduleBlock } from './components/ScheduleBlock'
import { Button } from '../../components/Button'
import {
  faBars,
  faChevronCircleDown,
  faChevronCircleLeft,
  faChevronCircleRight,
  faCog,
  faEllipsisVertical,
  faFileArrowDown,
  faHouse,
} from '@fortawesome/free-solid-svg-icons'
import { DepartmentStoreContext } from '../../store/departmentStore'
import { Department } from '../../Models/Department'
import Multiselect from '../../components/Multiselect'
import { KidStoreContext } from '../../store/kidStore'
import { Kid } from '../../Models/Kid'
import { getDocsData } from '../documents/getDocsData'
import { ShowWeeks } from './StatisticsDetail'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import classNames from 'classnames'
import html2canvas from 'html2canvas'
import { SearchKids } from '../ourKids/OurKids'
import { KidsStoreContext } from '../../store/kidsStore'
import MultiSelectOpen from '../../components/MultiSelectOpen'
import { Loading } from '../../components/Loading'
import Singleselect from '../../components/Singleselect'
import ColorCard from '../../components/ColorCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowDownAZ,
  faArrowUpAZ,
  faEllipsisStrokeVertical,
  faFilter,
} from '@fortawesome/pro-solid-svg-icons'
import { ButtonDropdown } from '../../components/Dropdown'
import { useClickOutside } from '../../hooks/useClickoutside'
import { PresenceScheduleDiffContainer } from './components/PresenceScheduleDiffContainer'
import { SortBy } from './components/SortBy'
import { Slider } from '../../components/Slider'
registerLocale('sv', sv)
registerLocale('en', en)
registerLocale('es', es)
export enum SORT_VALUES {
  FIRSTNAMEASC = 'firstNameAsc',
  FIRSTNAMEDESC = 'firstNameDesc',
  LASTNAMEASC = 'lastNameAsc',
  LASTNAMEDESC = 'lastNameDesc',
}

export const Statistics: React.FC = observer(() => {
  let { schoolId } = useParams()
  const navigate = useNavigate()
  const { setTitle } = useContext(NavigationStoreContext)
  const { t, i18n } = useTranslation()
  const {
    getStatistics,
    statistics,
    statisticsFromDate,
    setStatisticsFromDate,
    statisticsToDate,
    setStatisticsToDate,
    statisticsWeeksOnly,
    setStatisticsWeeksOnly,
  } = useContext(StatisticStoreContext)
  const isMonday = (date: Date) => {
    const day = date.getDay()
    if (!statisticsWeeksOnly) return day !== 1337
    return (
      day !== 2 && day !== 3 && day !== 4 && day !== 5 && day !== 6 && day !== 0
    )
  }
  const isSunday = (date: Date) => {
    const day = date.getDay()
    if (!statisticsWeeksOnly) return day !== 1337
    return (
      day !== 2 && day !== 3 && day !== 4 && day !== 5 && day !== 6 && day !== 1
    )
  }
  /*let [fromDate, setFromDate] = useState<Date>(
    new Date(
      moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
    ),
  )
  let [toDate, setToDate] = useState<Date>(
    new Date(
      moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
    ),
  )*/
  const { savedUserRoleId, fetchUserRole, currentUserRole } =
    useContext(UserStoreContext)
  const [loading, setLoading] = useState(false)
  const [exporting, setExporting] = useState(false)
  const {
    fetchDepartments,
    departments,
    setSelectedDepartments,
    selectedDepartments,
  } = useContext(DepartmentStoreContext)
  const { fetchKids, kids, setSelectedKids, selectedKids } =
    useContext(KidStoreContext)
  const { setSearch, search, saveKidTariff } = useContext(KidsStoreContext)
  let [firstDate, setFirstDate] = useState(moment().startOf('week'))
  let [lastDate, setLastDate] = useState(moment().endOf('week'))
  const [amountOfDays, setAmountOfDays] = useState(7)
  const [typeOfView, setTypeOfView] = useState<'day' | 'oneWeek' | 'month'>(
    'oneWeek',
  )

  const [sortBy, setSortBy] = useState<
    | SORT_VALUES.FIRSTNAMEASC
    | SORT_VALUES.FIRSTNAMEDESC
    | SORT_VALUES.LASTNAMEASC
    | SORT_VALUES.LASTNAMEDESC
  >(SORT_VALUES.FIRSTNAMEASC)
  const sortByOptions = [
    {
      label: t('statistics.firstNameAsc'),
      value: SORT_VALUES.FIRSTNAMEASC,
      icon: faArrowDownAZ,
    },
    {
      label: t('statistics.firstNameDesc'),
      value: SORT_VALUES.FIRSTNAMEDESC,
      icon: faArrowUpAZ,
    },
    {
      label: t('statistics.lastNameAsc'),
      value: SORT_VALUES.LASTNAMEASC,
      icon: faArrowDownAZ,
    },
    {
      label: t('statistics.lastNameDesc'),
      value: SORT_VALUES.LASTNAMEDESC,
      icon: faArrowUpAZ,
    },
  ]

  /*const [selectedDepartments, setSelectedDepartments] = useState<Department[]>(
    [],
  )*/
  /*let [selectedKids, setSelectedKids] = useState<Kid[]>([])*/
  const [showSchema, setShowSchema] = useState<boolean>(false)
  const [weeks, setWeeks] = useState<[any]>([''])
  const [showSum, setShowSum] = useState<boolean>(true)
  const [showSsn, setShowSsn] = useState<boolean>(false)
  const [fetchOnlyArchived, setFechOnlyArchived] = useState(false)
  const [historicSchemaCsvnExport, setHistoricSchemaCsvnExport] =
    useState<boolean>(false)

  const [departmentsToShow, setDepartmentsToShow] = useState<
    Department[] | undefined
  >([])

  useEffect(() => {
    if (selectedDepartments && selectedDepartments.length) {
      setDepartmentsToShow(selectedDepartments)
    } else {
      setDepartmentsToShow(
        departments
          ? [
              ...departments,
              //@ts-ignore
              {
                id: undefined,
                klassName: t('documents.missingDepartment'),
              } as Department,
            ]
          : undefined,
      )
    }
  }, [departments, selectedDepartments])

  const generatePdfViaCloud = async () => {
    //await Parse.Cloud.run('exportPdf')
    //await Parse.Cloud.run('testtest')
    fetch(
      'http://localhost:1337/parse/apps/ESR3nG8xRl6qaBahb31D3g5zBeyj7OjC/exportPdf',
    )
      .then((response) => response.json())
      .then((data) => console.log(data))
  }

  const hideElements = async () => {
    /*var divsToHide = document.getElementsByClassName('hideWhenExport') //divsToHide is an array
    for (var i = 0; i < divsToHide.length; i++) {
      //@ts-ignore
      divsToHide[i].style.visibility = 'hidden' // or
      //@ts-ignore
      divsToHide[i].style.display = 'none' // depending on what you're doing
    }*/
    document.querySelectorAll('.hideWhenExport').forEach((el) => el.remove())
  }
  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay))
  }

  const generateDays = (week: any) => {
    let weeks: any[] = []
    let firstDateOfWeek = moment(week.fromDate).startOf('week')
    let firstDateToAdd = moment(week.fromDate).startOf('week')
    /*for (var d = firstDateToAdd; d < lastDate; firstDateToAdd.add(1, 'days')) {
      if (!weeks.find((wn) => wn.week == moment(firstDateToAdd).get('week'))) {*/
    const days: Array<any> = []
    for (var x = 0; x < 7; x++) {
      days.push(firstDateOfWeek.format('YYYY-MM-DD'))
      firstDateOfWeek.add(1, 'days')
    }
    //weeks.push({ week: moment(firstDate).get('week'), days })
    //}
    //moment(firstDate).add(1, 'days');
    //}
    return { week, days }
  }

  const generateCsvSum = async () => {
    let rows: any = []
    let headers: any[] = []
    headers.push(t('statistics.kidName'))
    if (showSsn) {
      headers.push(t('statistics.socialsecurityNr'))
    }
    headers.push(t('statistics.totalScheduleTime'))
    if (
      moment(statisticsFromDate).format('YYYY-MM-DD') <
      moment().format('YYYY-MM-DD')
    ) {
      headers.push(t('statistics.totalPresence'))
      headers.push(t('statistics.totalDiff'))
    }

    headers.push(t('statistics.schedulePerWeek'))

    if (
      moment(statisticsFromDate).format('YYYY-MM-DD') <
      moment().format('YYYY-MM-DD')
    ) {
      headers.push(t('statistics.presencePerWeek')) // Per vecka
      headers.push(t('statistics.diffPerWeek'))
    }

    headers.push(t('statistics.schedulePerDay'))

    if (
      moment(statisticsFromDate).format('YYYY-MM-DD') <
      moment().format('YYYY-MM-DD')
    ) {
      headers.push(t('statistics.presencePerDay')) // Per dag
      headers.push(t('statistics.diffPerDay'))
    }

    headers.push(t('statistics.offHour'))
    headers.push(t('statistics.tariff'))
    departmentsToShow?.forEach((dep, index) => {
      if (index > 0) {
        rows.push([''])
      }
      rows.push([dep.klassName])
      rows.push([''])
      rows.push(headers)
      statistics?.kidsList
        ?.filter((k: any) => {
          const kid = kids?.find((kid) => kid.id === k.kidId)
          return kid?.attributes?.departmentPointer?.id === dep.id
        })
        ?.filter((k: any) => {
          if (search) {
            return k?.kidName?.toLowerCase().includes(search.toLowerCase())
          } else {
            return k
          }
        })
        .sort((a: any, b: any) => {
          if (sortBy === SORT_VALUES.FIRSTNAMEASC) {
            return a?.kidName.localeCompare(b?.kidName)
          } else if (sortBy === SORT_VALUES.FIRSTNAMEDESC) {
            return b?.kidName.localeCompare(a?.kidName)
          } else if (sortBy === SORT_VALUES.LASTNAMEASC) {
            return a?.kidLastName.localeCompare(b?.kidLastName)
          } else if (sortBy === SORT_VALUES.LASTNAMEDESC) {
            return b?.kidLastName.localeCompare(a?.kidLastName)
          }
        })
        .forEach((kid: any) => {
          /* START NY */
          //headers.push(t('statistics.kidName'))
          let rowElements = [kid.kidName]
          if (showSsn) {
            //headers.push(t('statistics.socialsecurityNr'))
            let kidSsn = kid.kidSsn || ''
            rowElements.push(kidSsn)
          }
          rowElements.push(
            kid.kidScheduleTotal?.hours +
              'h ' +
              kid.kidScheduleTotal?.minutes +
              'm',
          )
          if (
            moment(statisticsFromDate).format('YYYY-MM-DD') <
            moment().format('YYYY-MM-DD')
          ) {
            rowElements.push(
              kid.kidTimeAttendingTotal?.hours +
                'h ' +
                kid.kidTimeAttendingTotal?.minutes +
                'm',
            )
            rowElements.push(
              kid.kidTimeDifferenceTotal?.hours +
                'h ' +
                kid.kidTimeDifferenceTotal?.minutes +
                'm',
            )
          }

          rowElements.push(
            kid.kidScheduleHoursPerWeek?.hours +
              'h ' +
              kid.kidScheduleHoursPerWeek?.minutes +
              'm',
          )

          if (
            moment(statisticsFromDate).format('YYYY-MM-DD') <
            moment().format('YYYY-MM-DD')
          ) {
            rowElements.push(
              kid.kidTimeAttending?.hours +
                'h ' +
                kid.kidTimeAttending?.minutes +
                'm',
            )
            rowElements.push(
              kid.kidTimeDifference?.hours +
                'h ' +
                kid.kidTimeDifference?.minutes +
                'm',
            )
          }

          rowElements.push(
            kid.kidSchedulePerDay?.hours +
              'h ' +
              kid.kidSchedulePerDay?.minutes +
              'm',
          )
          if (
            moment(statisticsFromDate).format('YYYY-MM-DD') <
            moment().format('YYYY-MM-DD')
          ) {
            rowElements.push(
              kid.kidTimeAttendingPerDay?.hours +
                'h ' +
                kid.kidTimeAttendingPerDay?.minutes +
                'm',
            )
            rowElements.push(
              kid.kidTimeDifferencePerDay?.hours +
                'h ' +
                kid.kidTimeDifferencePerDay?.minutes +
                'm',
            )
          }

          rowElements.push(kid.kidScheduleTotal?.totalFree)
          let tariff = kid.tariff || ''
          rowElements.push(tariff)
          rows.push(rowElements)
        })

      let csvContent = 'data:text/csv;charset=utf-8,'

      rows.forEach(function (rowArray: any) {
        let row = Array.isArray(rowArray) ? rowArray.join(',') : rowArray
        csvContent += row + '\r\n'
      })

      var encodedUri = encodeURI(csvContent)
      var hiddenElement = document.createElement('a')
      hiddenElement.href = encodedUri
      hiddenElement.target = '_blank'
      hiddenElement.download =
        'summery-export_' +
        moment(statisticsFromDate).format('YYYY-MM-DD') +
        '-' +
        moment(statisticsToDate).format('YYYY-MM-DD') +
        '.csv'
      hiddenElement.click()
      //window.open(encodedUri)
    })
  }

  const generateCsv = async () => {
    let rows: any = []

    departmentsToShow?.forEach((dep, index) => {
      if (index > 0) {
        rows.push([''])
      }
      rows.push([dep.klassName])
      rows.push([''])
      weeks.forEach((week, index) => {
        if (index > 0) {
          rows.push([''])
        }
        rows.push([
          t('general.week') +
            ' ' +
            week.week +
            ' - ' +
            moment(week.fromDate).format('YYYY'),
        ])
        if (showSsn) {
          rows.push([
            t('statistics.kidName') +
              ';' +
              t('statistics.socialsecurityNr') +
              ';' +
              t('days.monday') +
              ';' +
              t('days.tuesday') +
              ';' +
              t('days.wednesday') +
              ';' +
              t('days.thursday') +
              ';' +
              t('days.friday') +
              ';' +
              t('general.total') +
              ';',
          ])
        } else {
          rows.push([
            t('statistics.kidName') +
              ';' +
              t('days.monday') +
              ';' +
              t('days.tuesday') +
              ';' +
              t('days.wednesday') +
              ';' +
              t('days.thursday') +
              ';' +
              t('days.friday') +
              ';' +
              t('general.total') +
              ';',
          ])
        }
        statistics?.kidsList
          ?.filter((k: any) => {
            const kid = kids?.find((kid) => kid.id === k.kidId)
            return kid?.attributes?.departmentPointer?.id === dep.id
          })
          ?.filter((k: any) => {
            if (search) {
              return k?.kidName?.toLowerCase().includes(search.toLowerCase())
            } else {
              return k
            }
          })
          .sort((a: any, b: any) => {
            if (sortBy === SORT_VALUES.FIRSTNAMEASC) {
              return a?.kidName.localeCompare(b?.kidName)
            } else if (sortBy === SORT_VALUES.FIRSTNAMEDESC) {
              return b?.kidName.localeCompare(a?.kidName)
            } else if (sortBy === SORT_VALUES.LASTNAMEASC) {
              return a?.kidLastName.localeCompare(b?.kidLastName)
            } else if (sortBy === SORT_VALUES.LASTNAMEDESC) {
              return b?.kidLastName.localeCompare(a?.kidLastName)
            }
          })
          .forEach((kid: any) => {
            let totalTimeAttendingHours = 0
            let totalTimeAttendingMinutes = 0
            const kidWeek = generateDays(week)
            let row = ''
            row = row + kid.kidName
            if (showSsn) {
              let kidSsn = ''
              if (kid.kidSsn) {
                kidSsn = '' + kid.kidSsn
              } else {
                kidSsn = '' //+ kid.kidSsn
              }

              row = row + ';' + kidSsn
            }
            kid &&
              kidWeek.days?.forEach((day: any) => {
                let log = kid.logs?.find(
                  (log: any) =>
                    moment(log.checkedIn).format('YYYY-MM-DD') === day &&
                    moment(log.checkedOut).format('YYYY-MM-DD') === day,
                )
                let checkedIn = kid.logs?.find(
                  (log: any) =>
                    moment(log.checkedIn).format('YYYY-MM-DD') === day,
                )?.checkedIn
                let checkedOut = kid.logs?.find(
                  (log: any) =>
                    moment(log.checkedOut).format('YYYY-MM-DD') === day,
                )?.checkedOut

                if (checkedIn && checkedOut) {
                  var diffMs = (checkedOut as any) - (checkedIn as any)
                  // TODO Should have better data type above.
                  var diffHrs = Math.floor((diffMs % 86400000) / 3600000)
                  var diffMins = Math.round(
                    ((diffMs % 86400000) % 3600000) / 60000,
                  )
                  totalTimeAttendingHours = totalTimeAttendingHours + diffHrs
                  totalTimeAttendingMinutes =
                    totalTimeAttendingMinutes + diffMins
                }

                let scheduleStart = kid.kidSchedule?.find(
                  (sched: any) => sched.date == day,
                )?.from
                let scheduleEnd = kid.kidSchedule?.find(
                  (sched: any) => sched.date == day,
                )?.to
                if (moment(day).day() === 6) {
                  row =
                    row +
                    ';' +
                    totalTimeAttendingHours +
                    'h ' +
                    totalTimeAttendingMinutes +
                    'm'
                }
                if (moment(day).day() == 0 || moment(day).day() == 6) {
                  //row = row + ';' + ''
                  return
                }

                if (
                  moment(day).format('YYYY-MM-DD') <
                  moment(statisticsFromDate).format('YYYY-MM-DD')
                ) {
                  row = row + ';' + ''
                  return
                }

                if (
                  moment(day).format('YYYY-MM-DD') >
                  moment(statisticsToDate).format('YYYY-MM-DD')
                ) {
                  row = row + ';' + ''
                  return
                }

                if (checkedIn && checkedOut) {
                  row =
                    row +
                    ';' +
                    moment(checkedIn).format('HH:mm') +
                    ' - ' +
                    moment(checkedOut).format('HH:mm')
                  if (
                    scheduleStart &&
                    scheduleEnd &&
                    scheduleStart !== 'ledig' &&
                    historicSchemaCsvnExport
                  ) {
                    row =
                      row + ' ( ' + scheduleStart + ' - ' + scheduleEnd + ' )'
                  }
                  return
                }

                if (
                  kid.closedDates?.find(
                    (cD: string) => cD === moment(day).format('YYYY-MM-DD'),
                  )
                ) {
                  row = row + ';' + t('statistics.closed')
                  return
                }

                if (scheduleStart == 'ledig') {
                  row = row + ';' + t('statistics.offHour')
                  return
                }

                if (day >= moment().format('YYYY-MM-DD')) {
                  if (scheduleStart && scheduleEnd) {
                    row = row + ';' + scheduleStart + ' - ' + scheduleEnd
                    return
                  }
                }

                if (day < moment().format('YYYY-MM-DD')) {
                  if (checkedIn && checkedOut) {
                    row =
                      row +
                      ';' +
                      moment(checkedIn).format('HH:mm') +
                      ' - ' +
                      moment(checkedOut).format('HH:mm')
                    if (
                      scheduleStart &&
                      scheduleEnd &&
                      historicSchemaCsvnExport
                    ) {
                      row =
                        row + ' ( ' + scheduleStart + ' - ' + scheduleEnd + ' )'
                    }
                    return
                  } else {
                    row = row + ';' + t('absence.absence')
                    if (
                      scheduleStart &&
                      scheduleEnd &&
                      historicSchemaCsvnExport
                    ) {
                      row =
                        row + ' ( ' + scheduleStart + ' - ' + scheduleEnd + ' )'
                    }
                    return
                  }
                }

                row = row + ';' + ''
              })

            rows.push([row])
          })
      })
    })

    let csvContent = 'data:text/csv;charset=utf-8,'

    rows.forEach(function (rowArray: any) {
      let row = rowArray.join(';')
      csvContent += row + '\r\n'
    })
    var encodedUri = encodeURI(csvContent)
    var hiddenElement = document.createElement('a')
    hiddenElement.href = encodedUri
    hiddenElement.target = '_blank'
    hiddenElement.download =
      'weekly-export_' +
      moment(statisticsFromDate).format('YYYY-MM-DD') +
      '-' +
      moment(statisticsToDate).format('YYYY-MM-DD') +
      '.csv'
    hiddenElement.click()
    //window.open(encodedUri)
  }

  const generatePdf = async (type: 'p' | 'l') => {
    //await hideElements()
    setExporting(true)
    await timeout(1000)
    const doc = new jsPDF(type)

    if (showSum) {
      for (
        var i = 0;
        i < (departmentsToShow ? departmentsToShow.length : 1);
        i++
      ) {
        autoTable(doc, {
          //startY: 30,

          //html: showSum ? '#my-table-header' : '#my-table-header' + i,
          html: '#my-table-header' + i,
          //theme: 'striped',
          useCss: true,
          includeHiddenHtml: false,
          //styles: { cellPadding: 10 },
        })

        autoTable(doc, {
          //startY: 30,
          //html: showSum ? '#my-table' : '#my-table' + i,
          html: '#my-table' + i,
          //theme: 'striped',
          useCss: true,
          includeHiddenHtml: false,
          didDrawPage: function (data) {
            // @ts-ignore
            const pageCount = doc.internal.getNumberOfPages()

            doc.setFontSize(8)

            var pageSize = doc.internal.pageSize
            var pageHeight = pageSize.height
              ? pageSize.height
              : pageSize.getHeight()
          },
        })
      }
    } else {
      for (
        var i = 0;
        i < (departmentsToShow ? departmentsToShow.length : 1);
        i++
      ) {
        autoTable(doc, {
          //startY: 30,

          //html: showSum ? '#my-table-header' : '#my-table-header' + i,
          html: '#my-table-header' + i,
          //theme: 'striped',
          useCss: true,
          includeHiddenHtml: false,
          //styles: { cellPadding: 10 },
        })
        for (var x = 0; x < weeks.length; x++) {
          autoTable(doc, {
            //startY: 30,

            //html: showSum ? '#my-table-header' : '#my-table-header' + i,
            html: '#my-table-header' + i + x,
            //theme: 'striped',
            useCss: true,
            includeHiddenHtml: false,
            //styles: { cellPadding: 10 },
          })

          autoTable(doc, {
            //startY: 30,
            //html: showSum ? '#my-table' : '#my-table' + i,
            html: '#my-table' + i + x,
            //theme: 'striped',
            useCss: true,
            includeHiddenHtml: false,
            didDrawPage: function (data) {
              // @ts-ignore
              const pageCount = doc.internal.getNumberOfPages()

              doc.setFontSize(8)

              var pageSize = doc.internal.pageSize
              var pageHeight = pageSize.height
                ? pageSize.height
                : pageSize.getHeight()
            },
          })
        }
      }
    }
    // Or use javascript directly:
    /*autoTable(doc, {
      head: [['Name', 'Email', 'Country']],
      body: [
        ['David', 'david@example.com', 'Sweden'],
        ['Castille', 'castille@example.com', 'Spain'],
        // ...
      ],
    })*/
    let docName = ''
    if (showSum) {
      docName =
        'tyra-export-summery_' +
        moment(statisticsFromDate).format('YYYY-MM-DD') +
        '-' +
        moment(statisticsToDate).format('YYYY-MM-DD') +
        '.pdf'
    } else {
      docName =
        'tyra-export-weekly_' +
        moment(statisticsFromDate).format('YYYY-MM-DD') +
        '-' +
        moment(statisticsToDate).format('YYYY-MM-DD') +
        '.pdf'
    }
    doc.save(docName)

    setExporting(false)
  }

  useEffect(() => {
    if (savedUserRoleId && schoolId) {
      /*getStatistics(
        savedUserRoleId,
        fromDate,
        toDate,
        selectedDepartments,
        selectedKids,
        schoolId,
      )*/

      //setLoading(true)
      fetchUserRole(savedUserRoleId).finally(() => {
        //setLoading(false)
      })
    }
  }, [])

  useEffect(() => {
    setLoading(true)
    if (kids) {
      getData().finally(() => {
        setLoading(false)
      })
    }
  }, [
    kids,
    selectedKids,
    selectedDepartments,
    statisticsFromDate,
    statisticsToDate,
  ])

  useEffect(() => {
    if (schoolId) {
      setLoading(true)
      fetchDepartments(schoolId).finally(() => {
        //setLoading(false)
      })
      fetchKids(schoolId, undefined, fetchOnlyArchived).finally(() => {
        setLoading(false)
      })
    }
  }, [schoolId, fetchOnlyArchived])

  useEffect(() => {
    setTitle(t('menu.attendance'))
  })

  const onChangeFromDate = async (date: Date) => {
    //statisticsFromDate = date
    await setStatisticsFromDate(date).finally(() => {
      if (savedUserRoleId && schoolId) {
        /*  getStatistics(
          savedUserRoleId,
          date,
          toDate,
          selectedDepartments,
          selectedKids,
          schoolId,
        )*/
        //getData()
      }
    })
  }

  const onChangeToDate = async (date: Date) => {
    //toDate = date
    await setStatisticsToDate(date).finally(() => {
      if (savedUserRoleId && schoolId) {
        /*getStatistics(
          savedUserRoleId,
          fromDate,
          date,
          selectedDepartments,
          selectedKids,
          schoolId,
        )*/
        // getData()
      }
    })
  }

  const dateBackward = () => {
    if (typeOfView == 'oneWeek') {
      firstDate = moment(firstDate).subtract(1, 'weeks')
      lastDate = moment(lastDate).subtract(1, 'weeks')
      setFirstDate(firstDate)
      setLastDate(lastDate)
      if (!statisticsFromDate && !statisticsToDate) {
        getData(firstDate, lastDate)
      }
    } else if (typeOfView == 'day') {
      firstDate = moment(firstDate).subtract(1, 'day')
      lastDate = moment(lastDate).subtract(1, 'day')
      setFirstDate(firstDate)
      setLastDate(lastDate)
      if (!statisticsFromDate && !statisticsToDate) {
        getData(firstDate, lastDate)
      }
    } else if (typeOfView == 'month') {
      firstDate = moment(firstDate).subtract(1, 'month')
      lastDate = moment(lastDate).subtract(1, 'month')
      setFirstDate(firstDate)
      setLastDate(lastDate)
      if (!statisticsFromDate && !statisticsToDate) {
        getData(firstDate, lastDate)
      }
    }
    /*if (!fromDate && !toDate) {
      getData()
    }*/
  }

  const dateForward = () => {
    if (typeOfView == 'oneWeek') {
      firstDate = moment(firstDate).add(1, 'weeks')
      lastDate = moment(lastDate).add(1, 'weeks')
      setFirstDate(firstDate)
      setLastDate(lastDate)
      if (!statisticsFromDate && !statisticsToDate) {
        getData(firstDate, lastDate)
      }
    } else if (typeOfView == 'day') {
      firstDate = moment(firstDate).add(1, 'day')
      lastDate = moment(lastDate).add(1, 'day')
      setFirstDate(firstDate)
      setLastDate(lastDate)
      if (!statisticsFromDate && !statisticsToDate) {
        getData(firstDate, lastDate)
      }
    } else if (typeOfView == 'month') {
      firstDate = moment(firstDate).add(1, 'month')
      lastDate = moment(lastDate).add(1, 'month')
      setFirstDate(firstDate)
      setLastDate(lastDate)
      if (!statisticsFromDate && !statisticsToDate) {
        getData(firstDate, lastDate)
      }
    }
  }

  const getData = async (
    calendarFromDate: any = null,
    calendarToDate: any = null,
    kidsChange: Kid[] | null = null,
  ) => {
    setLoading(true)
    if (savedUserRoleId && schoolId) {
      if (selectedKids?.length || (kidsChange && kidsChange?.length)) {
        if (statisticsFromDate && statisticsToDate) {
          getStatistics(
            savedUserRoleId,
            statisticsFromDate,
            statisticsToDate,
            selectedDepartments,
            kidsChange ? kidsChange : selectedKids,
            schoolId,
            fetchOnlyArchived,
          ).finally(() => {
            setLoading(false)
          })
        } else {
          getStatistics(
            savedUserRoleId,
            new Date(calendarFromDate.format('Y-M-D HH:mm')),
            new Date(calendarToDate.format('Y-M-D HH:mm')),
            selectedDepartments,
            kidsChange ? kidsChange : selectedKids,
            schoolId,
            fetchOnlyArchived,
          ).finally(() => {
            setLoading(false)
          })
        }
      } else {
        if (statisticsFromDate && statisticsToDate) {
          getStatistics(
            savedUserRoleId,
            statisticsFromDate,
            statisticsToDate,
            selectedDepartments,
            kids,
            schoolId,
            fetchOnlyArchived,
          ).finally(() => {
            setLoading(false)
          })
        } else {
          getStatistics(
            savedUserRoleId,
            new Date(calendarFromDate.format('Y-M-D HH:mm')),
            new Date(calendarToDate.format('Y-M-D HH:mm')),
            selectedDepartments,
            kids,
            schoolId,
            fetchOnlyArchived,
          ).finally(() => {
            setLoading(false)
          })
        }
      }
    }

    const selectedWeeks: any = []
    const startWeek = moment(statisticsFromDate)
    let weekDiff = moment(statisticsToDate).diff(startWeek, 'week') + 1

    for (var i = 0; i < weekDiff; i++) {
      selectedWeeks.push({
        week: moment(startWeek).week(),
        fromDate: moment(startWeek).startOf('week'),
        toDate: moment(startWeek).endOf('week'),
      })
      startWeek.add(1, 'weeks')
    }
    setWeeks(selectedWeeks)
  }
  const [focusRing1, setFocusRing1] = useState(false)
  const [focusRing2, setFocusRing2] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [filterOpen, setFilterOpen] = useState(false)
  const [menuRef, togglerRef] = useClickOutside(() => setIsOpen(false))
  const [menuRefFilter, togglerRefFilter] = useClickOutside(() => {
    setFilterOpen(false)
  })

  const tariffValues = [
    { value: null, label: t('statistics.tariffNothingSelected') },
    {
      value: '-15',
      label: t('statistics.tariffMaxHours').replace('XXtimmarXX', '15'),
    },
    {
      value: '15',
      label: t('statistics.tariffHoursOver').replace('XXtimmarXX', '15'),
    },
    {
      value: '-20',
      label: t('statistics.tariffMaxHours').replace('XXtimmarXX', '20'),
    },
    {
      value: '20',
      label: t('statistics.tariffHoursOver').replace('XXtimmarXX', '20'),
    },
    {
      value: '-30',
      label: t('statistics.tariffMaxHours').replace('XXtimmarXX', '30'),
    },
    {
      value: '30',
      label: t('statistics.tariffHoursOver').replace('XXtimmarXX', '30'),
    },
    {
      value: '-40',
      label: t('statistics.tariffMaxHours').replace('XXtimmarXX', '40'),
    },
    {
      value: '40',
      label: t('statistics.tariffHoursOver').replace('XXtimmarXX', '40'),
    },
  ]

  function removeEmojis(str: string) {
    return str.replace(
      /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F1E6}-\u{1F1FF}\u{1F191}-\u{1F251}\u{1F004}\u{1F0CF}\u{1F170}-\u{1F171}\u{1F17E}-\u{1F17F}\u{1F18E}\u{3030}\u{2B50}\u{2B55}\u{2934}-\u{2935}\u{2B05}-\u{2B07}\u{2B1B}-\u{2B1C}\u{3297}\u{3299}\u{303D}\u{00A9}\u{00AE}\u{2122}\u{23F3}\u{24C2}\u{23E9}-\u{23EF}\u{25B6}\u{23F8}-\u{23FA}]/gu,
      '',
    )
  }

  return (
    <Page
      naked
      loading={loading}
      clearFilter={() => {
        setSelectedDepartments([])
        setSelectedKids([])
        if (schoolId) {
          fetchKids(schoolId, []).finally(() => {
            setLoading(false)
          })
        }
      }}
      filterCount={
        (selectedDepartments?.length ? selectedDepartments.length : 0) +
        (selectedKids?.length ? selectedKids?.length : 0)
      }
      filter={
        <>
          <div className="flex justify-end mt-[-15px] mb-4">
            <div className="flex gap-x-4 align-middle">
              <div className="font-semibold">
                {t('statistics.showOnlyArchived')}
              </div>
              <Slider
                id={0}
                onSliderClick={() => {
                  setSelectedKids([])
                  setFechOnlyArchived(!fetchOnlyArchived)
                }}
                onBoxClick={() => {}}
                value={fetchOnlyArchived}
              />
            </div>
          </div>
          <div className="w-full flex flex-col md:flex-row items-center justify-center md:items-start gap-4 ">
            <MultiSelectOpen
              onChange={(e) => {
                let departments = e
                setSelectedDepartments(e)
                if (schoolId) {
                  fetchKids(schoolId, departments, fetchOnlyArchived).finally(
                    () => {
                      setLoading(false)
                    },
                  )
                }
              }}
              items={departments as Department[]}
              initialSelectedItems={selectedDepartments}
              itemVariableKey={'klassName'}
              placeholder={t('general.department')}
            />

            <MultiSelectOpen
              onChange={(e) => {
                setSelectedKids(e)
              }}
              items={kids as Kid[]}
              initialSelectedItems={selectedKids}
              itemVariableKey={'kidName'}
              placeholder={t('general.kids')}
            />
          </div>
        </>
      }
      searchFilter={
        <SearchKids
          onSearch={(s: string) => {
            setSearch(s)
          }}
          initialValue={search}
        />
      }
      topBar={
        <>
          <div className="flex justify-center w-fit items-center sm:flex-row gap-x-4">
            <div className="flex justify-center bg-white rounded-lg  w-fitt p-1">
              <div className="flex justify-center items-center flex-col sm:flex-row gap-x-4">
                <div className="relative">
                  <DatePicker
                    className="border border-gray-200 hover:border-eventsMain text-xs sm:text-sm cursor-pointer  max-w-[100px] p-[1px] sm:p-2 rounded-lg text-center focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                    dateFormat="yyyy-MM-dd"
                    locale={i18n.language}
                    selected={statisticsFromDate as unknown as Date}
                    onChange={(date: Date) => {
                      onChangeFromDate(date)
                      setFocusRing1(false)
                    }}
                    onFocus={() => setFocusRing1(true)}
                    onBlur={() => {
                      setFocusRing1(false)
                    }}
                    filterDate={isMonday}
                    onKeyDown={(e) => {
                      e.preventDefault()
                    }}
                  />
                </div>
                <div className="text-gray-600 font-normal text-xs sm:text-sm">
                  {t('general.to')}
                </div>
                <div className="relative">
                  <DatePicker
                    className="border border-gray-200 hover:border-eventsMain text-xs sm:text-sm cursor-pointer  max-w-[100px] p-[1px] sm:p-2 rounded-lg text-center focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                    dateFormat="yyyy-MM-dd"
                    locale={i18n.language}
                    selected={statisticsToDate as unknown as Date}
                    onChange={(date: Date) => {
                      onChangeToDate(date)
                      setFocusRing2(false)
                    }}
                    onFocus={() => setFocusRing2(true)}
                    onBlur={() => {
                      setFocusRing2(false)
                    }}
                    filterDate={isSunday}
                    onKeyDown={(e) => {
                      e.preventDefault()
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      }
    >
      {loading && <Loading />}
      {exporting && (
        <div className="fixed top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.7)] z-[1000] flex justify-center items-center text-white">
          <div>{t('general.exporting')}</div>
        </div>
      )}
      <ColorCard
        //bgColor="bg-eventsMain"
        bgColor="bg-white"
      >
        <div className="flex flex-col justify-center h-full">
          <div className="w-full mx-auto bg-white rounded-sm my-div">
            <div className="flex flex-wrap w-full gap-4 justify-center  "></div>
            <div className="flex flex-wrap w-full gap-4 mb-8 justify-between">
              <div className="flex justify-between px-5 flex-wrap w-full">
                <div className="flex  justify-between sm:flex-row gap-x-4 w-full relative">
                  <div className="flex flex-wrap gap-4">
                    <Button
                      size="sm"
                      variant={showSum ? 'fullBlue' : 'borderBlue'}
                      label={t('statistics.summary')}
                      onClick={() => setShowSum(true)}
                    />
                    <Button
                      size="sm"
                      variant={showSum ? 'borderBlue' : 'fullBlue'}
                      label={t('statistics.dayByDay')}
                      onClick={() => setShowSum(false)}
                    />
                  </div>
                  <div className="flex h-10">
                    <div className="flex gap-x-2">
                      <Button
                        size="sm"
                        variant="fullGray"
                        label={t('contactList.exportCSV')}
                        onClick={() => {
                          showSum ? generateCsvSum() : generateCsv()
                        }}
                      />

                      {!showSum && (
                        <>
                          <Button
                            size="sm"
                            variant="fullGray"
                            label={t('statistics.exportLandscape')}
                            onClick={() => generatePdf('l')}
                          />
                          <Button
                            size="sm"
                            variant="fullGray"
                            label={t('statistics.exportPortrait')}
                            onClick={() => generatePdf('p')}
                          />
                        </>
                      )}
                    </div>
                    <div className="absolute bottom-[-50px] right-2 text-sm font-normal justify-end">
                      <label className="flex flex-row gap-x-2 justify-end">
                        <>{t('statistics.includePSNexport')}</>
                        <input
                          id="showSsn"
                          type="checkbox"
                          checked={showSsn}
                          //className="hidden-checkbox"
                          onChange={() => setShowSsn(!showSsn)}
                        />
                      </label>
                      {!showSum && (
                        <label className="flex flex-row gap-x-2 justify-end">
                          <>{t('statistics.includeHistoricSchemaCsvExport')}</>
                          <input
                            id="showSsn"
                            type="checkbox"
                            checked={historicSchemaCsvnExport}
                            //className="hidden-checkbox"
                            onChange={() =>
                              setHistoricSchemaCsvnExport(
                                !historicSchemaCsvnExport,
                              )
                            }
                          />
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {departmentsToShow?.map((dep: Department, indexDeps) => {
              if (
                !statistics?.kidsList
                  ?.filter((k: any) => {
                    const kid = kids?.find((kid) => kid.id === k.kidId)
                    // if (!fetchOnlyArchived) {
                    return kid?.attributes?.departmentPointer?.id === dep.id
                    /*} else {
                      return kid
                    }*/
                  })
                  ?.filter((k: any) => {
                    if (search) {
                      return k?.kidName
                        ?.toLowerCase()
                        .includes(search.toLowerCase())
                    } else {
                      return k
                    }
                  })
                  .sort((a: any, b: any) => {
                    if (sortBy === SORT_VALUES.FIRSTNAMEASC) {
                      return a?.kidName.localeCompare(b?.kidName)
                    } else if (sortBy === SORT_VALUES.FIRSTNAMEDESC) {
                      return b?.kidName.localeCompare(a?.kidName)
                    } else if (sortBy === SORT_VALUES.LASTNAMEASC) {
                      return a?.kidLastName.localeCompare(b?.kidLastName)
                    } else if (sortBy === SORT_VALUES.LASTNAMEDESC) {
                      return b?.kidLastName.localeCompare(a?.kidLastName)
                    }
                  }).length
              ) {
                return null
              }
              /* if (fetchOnlyArchived && indexDeps > 0) {
                return null
              }*/
              return (
                <>
                  <div className="flex flex-row w-full justify-between px-5 mb-4">
                    <h2 className="text-xl text-black font-bold">
                      <FontAwesomeIcon icon={faHouse} size="lg" />{' '}
                      {exporting
                        ? removeEmojis(dep.klassName ?? '')
                        : dep.klassName}
                    </h2>
                  </div>

                  <table
                    id={'my-table-header' + indexDeps}
                    className="table-auto w-full text-left testtt hidden"
                  >
                    <thead>
                      <tr className="flex flex-wrap justify-center text-3xl font-medium my-2 h-10">
                        {/*<th>{t('statistics.summary')}</th>*/}
                        <th>
                          {exporting
                            ? removeEmojis(dep.klassName ?? '')
                            : dep.klassName}
                        </th>
                      </tr>
                    </thead>
                  </table>
                  {showSum ? (
                    <>
                      {/* Start summery new */}
                      <div className="p-3">
                        <div className="overflow-y-auto p-2">
                          <table
                            id={'my-table-header' + indexDeps}
                            className="table-auto w-full text-left testtt hidden"
                          >
                            <thead>
                              <tr className="flex flex-wrap justify-center text-3xl font-medium my-2 h-10">
                                {/*<th>{t('statistics.summary')}</th>*/}
                                <th>{dep.klassName}</th>
                              </tr>
                            </thead>
                          </table>
                          <table
                            className="table-auto w-full"
                            //style={{ pageBreakAfter: 'always', width: 1200 }}
                            style={{ pageBreakAfter: 'always' }}
                            id={'my-table' + indexDeps}
                          >
                            <thead className="text-xs font-semibold">
                              <tr>
                                <th className="p-2 whitespace-nowrap rounded-l-xl font-semibold text-left">
                                  <span className="text-base font-bold">
                                    {t('statistics.kidName')}
                                  </span>
                                  <br />
                                  <SortBy
                                    sortBy={sortBy}
                                    sortByOptions={sortByOptions}
                                    onChange={(value) => {
                                      setSortBy(value)
                                    }}
                                  />
                                </th>

                                <th className="p-2 whitespace-nowrap font-semibold text-left">
                                  <span className="text-base font-bold">
                                    {t('general.total')}
                                  </span>
                                  <br />
                                  <span className="text-2xs">
                                    <>{t('statistics.preSelectedRange')}</>
                                  </span>
                                </th>
                                <th className="p-2 whitespace-nowrap font-semibold text-left">
                                  <span className="text-base font-bold">
                                    <>{t('statistics.averagePerWeek')}</>
                                  </span>
                                  <br />
                                  <span className="text-2xs">
                                    <b>
                                      {' '}
                                      <>{t('statistics.obs')}</>
                                    </b>{' '}
                                    <>{t('statistics.basedOnAveragePerWeek')}</>
                                  </span>
                                </th>
                                <th className="p-2 whitespace-nowrap font-semibold text-left">
                                  <span className="text-base font-bold">
                                    {t('statistics.offHour')}
                                  </span>
                                  <br />
                                  <span className="text-2xs">
                                    <br />
                                  </span>
                                </th>
                                <th className="p-2 whitespace-nowrap rounded-r-xl font-semibold text-center">
                                  <span className="text-base font-bold">
                                    {t('statistics.tariff')}
                                  </span>
                                  <br />
                                  <span className="text-2xs">
                                    <br />
                                  </span>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="text-sm divide-y divide-gray-100">
                              {statistics?.kidsList
                                ?.filter((k: any) => {
                                  const kid = kids?.find(
                                    (kid) => kid.id === k.kidId,
                                  )
                                  /* if (fetchOnlyArchived) {
                                    return kid
                                  } else {*/
                                  return (
                                    kid?.attributes?.departmentPointer?.id ===
                                    dep.id
                                  )
                                  // }
                                })
                                ?.filter((k: any) => {
                                  if (search) {
                                    return k?.kidName
                                      ?.toLowerCase()
                                      .includes(search.toLowerCase())
                                  } else {
                                    return k
                                  }
                                })
                                .sort((a: any, b: any) => {
                                  if (sortBy === SORT_VALUES.FIRSTNAMEASC) {
                                    return a?.kidName.localeCompare(b?.kidName)
                                  } else if (
                                    sortBy === SORT_VALUES.FIRSTNAMEDESC
                                  ) {
                                    return b?.kidName.localeCompare(a?.kidName)
                                  } else if (
                                    sortBy === SORT_VALUES.LASTNAMEASC
                                  ) {
                                    return a?.kidLastName.localeCompare(
                                      b?.kidLastName,
                                    )
                                  } else if (
                                    sortBy === SORT_VALUES.LASTNAMEDESC
                                  ) {
                                    return b?.kidLastName.localeCompare(
                                      a?.kidLastName,
                                    )
                                  }
                                })
                                .map((kid: any, index: number) => {
                                  return (
                                    <tr
                                      key={index}
                                      className="hover:bg-blue-50"
                                    >
                                      <a
                                        href={`/statistics/${schoolId}/${kid.kidId}`}
                                        target="_self"
                                        className="block p-2 whitespace-nowrap font-bold text-gray-800"
                                      >
                                        <td className="cursor-pointer">
                                          <div className="flex items-center">
                                            <div className="flex-shrink-0 mr-2 sm:mr-3">
                                              <img
                                                className="rounded-full"
                                                src={
                                                  kid.kidImage
                                                    ? kid.kidImage
                                                    : process.env.PUBLIC_URL +
                                                      '/images/placeholder-image.png'
                                                }
                                                width="60"
                                                height="60"
                                                alt={''}
                                              />
                                            </div>
                                            <div className="text-ellipsis overflow-hidden">
                                              {sortBy ===
                                                SORT_VALUES.FIRSTNAMEASC ||
                                              sortBy ===
                                                SORT_VALUES.FIRSTNAMEDESC
                                                ? kid.kidFirstName
                                                : null}
                                              {sortBy ===
                                                SORT_VALUES.LASTNAMEASC ||
                                              sortBy ===
                                                SORT_VALUES.LASTNAMEDESC
                                                ? kid.kidLastName
                                                : null}
                                              <br />{' '}
                                              <span className="font-normal">
                                                {sortBy ===
                                                  SORT_VALUES.FIRSTNAMEASC ||
                                                sortBy ===
                                                  SORT_VALUES.FIRSTNAMEDESC
                                                  ? kid.kidLastName
                                                  : null}
                                                {sortBy ===
                                                  SORT_VALUES.LASTNAMEASC ||
                                                sortBy ===
                                                  SORT_VALUES.LASTNAMEDESC
                                                  ? kid.kidFirstName
                                                  : null}
                                              </span>
                                              <br />
                                              {!exporting ? (
                                                <span className="text-xs text-gray-700 font-normal">
                                                  {kid.kidSsn}
                                                </span>
                                              ) : showSsn ? (
                                                <span className="text-xs text-gray-700 font-normal">
                                                  {kid.kidSsn}
                                                </span>
                                              ) : null}
                                            </div>
                                          </div>
                                        </td>
                                      </a>
                                      <td className="p-2 whitespace-nowrap text-left font-medium text-gray-800">
                                        <PresenceScheduleDiffContainer
                                          presence={kid?.kidTimeAttendingTotal}
                                          schema={kid?.kidScheduleTotal}
                                          diff={kid?.kidTimeDifferenceTotal}
                                          compact={false}
                                          showHeader={false}
                                        />
                                      </td>
                                      <td className="p-2 whitespace-nowrap text-left font-medium text-gray-800">
                                        <PresenceScheduleDiffContainer
                                          presence={kid?.kidTimeAttending}
                                          schema={kid?.kidScheduleHoursPerWeek}
                                          diff={kid?.kidTimeDifference}
                                          compact={false}
                                          showHeader={false}
                                        />
                                      </td>
                                      <td className="p-2 whitespace-nowrap text-left font-medium text-gray-800">
                                        {kid.kidScheduleHoursPerWeek.totalFree +
                                          ' '}
                                        {kid.kidScheduleHoursPerWeek
                                          ?.totalFreeNotBeen > 0 && (
                                          <>
                                            <span className="text-orange-400 group relative w-max cursor-help">
                                              {' '}
                                              (
                                              {
                                                kid.kidScheduleHoursPerWeek
                                                  .totalFreeNotBeen
                                              }
                                              )
                                              <span className="pointer-events-none absolute -top-10 right-0 w-max opacity-0 transition-opacity group-hover:opacity-100 border p-1 rounded-xl text-xs bg-gray-100 border-gray-200 text-gray-900">
                                                {t('statistics.freeNotBeen')}
                                              </span>
                                            </span>{' '}
                                          </>
                                        )}
                                        {' ' + t('absence.absenceDays')}{' '}
                                      </td>
                                      <td className="p-2 whitespace-nowrap text-lg text-center">
                                        <Singleselect
                                          dropdownClassName="w-[295px] -right-[25px] rounded-full flex flex-col w-full border cursor-pointer"
                                          removeSelected
                                          hasBorder
                                          maxWidth="190px"
                                          onChange={(e: any) => {
                                            saveKidTariff(
                                              kid,
                                              Number(e.value),
                                              kid.kidId,
                                            ).finally(() => {
                                              getData()
                                            })
                                          }}
                                          items={tariffValues}
                                          initialSelectedItem={
                                            kid && kid.tariff
                                              ? tariffValues.find(
                                                  (v) =>
                                                    Number(v.value) ==
                                                    Number(kid.tariff),
                                                )
                                              : {
                                                  value: null,
                                                  label: t(
                                                    'statistics.tariffNothingSelected',
                                                  ),
                                                }
                                          }
                                          itemVariableKey={'label'}
                                          placeholder={t('statistics.tariff')}
                                        />
                                      </td>
                                    </tr>
                                  )
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* End summery new */}
                    </>
                  ) : (
                    <>
                      {/* Start week for week new */}
                      <div className="p-3">
                        <div className="overflow-y-auto">
                          {weeks.map((week, indexWeeks) => {
                            return (
                              <div className="p-2 my-2 shadow-sm rounded-b-md mb-20 ">
                                <table
                                  id={
                                    'my-table-header' + indexDeps + indexWeeks
                                  }
                                  className="table-auto w-full text-left testtt"
                                >
                                  <thead>
                                    <tr className="flex flex-wrap text-xl font-medium my-2 h-auto">
                                      <th>
                                        {t('general.week')} {week.week} -{' '}
                                        {moment(week.fromDate)
                                          .add(1, 'days')
                                          .format('YYYY')}
                                        <span className="text-xs text-gray-700">
                                          <br />
                                          {moment(week.fromDate)
                                            .add(1, 'days')
                                            .format('YYYY-MM-DD')}{' '}
                                          -{' '}
                                          {moment(week.toDate)
                                            .add(1, 'days')
                                            .format('YYYY-MM-DD')}
                                        </span>
                                      </th>
                                    </tr>
                                  </thead>
                                </table>
                                <table
                                  className={'table-auto w-full testtt'}
                                  id={'my-table' + indexDeps + indexWeeks}
                                >
                                  <thead className="text-xs font-semibold">
                                    <tr>
                                      <th className="p-2 whitespace-nowrap rounded-l-xl font-semibold text-left">
                                        <span className="text-base font-bold">
                                          {t('statistics.kidName')}
                                        </span>
                                        <br />
                                        {!exporting && (
                                          <SortBy
                                            sortBy={sortBy}
                                            sortByOptions={sortByOptions}
                                            onChange={(value) => {
                                              setSortBy(value)
                                            }}
                                          />
                                        )}
                                      </th>
                                      <th className="p-2 whitespace-nowrap w-[13%] text-center">
                                        <span className="text-base font-bold">
                                          {t('days.monday')}
                                        </span>
                                        <br />
                                        <span className="text-2xs">
                                          <br />
                                        </span>
                                      </th>
                                      <th className="p-2 whitespace-nowrap w-[13%] text-center">
                                        <span className="text-base font-bold">
                                          {t('days.tuesday')}
                                        </span>
                                        <br />
                                        <span className="text-2xs">
                                          <br />
                                        </span>
                                      </th>
                                      <th className="p-2 whitespace-nowrap w-[13%] text-center">
                                        <span className="text-base font-bold">
                                          {t('days.wednesday')}
                                        </span>
                                        <br />
                                        <span className="text-2xs">
                                          <br />
                                        </span>
                                      </th>
                                      <th className="p-2 whitespace-nowrap w-[13%] text-center">
                                        <span className="text-base font-bold">
                                          {t('days.thursday')}
                                        </span>
                                        <br />
                                        <span className="text-2xs">
                                          <br />
                                        </span>
                                      </th>
                                      <th className="p-2 whitespace-nowrap w-[13%] text-center">
                                        <span className="text-base font-bold">
                                          {t('days.friday')}
                                        </span>
                                        <br />
                                        <span className="text-2xs">
                                          <br />
                                        </span>
                                      </th>
                                      <th className="p-2 whitespace-nowrap w-[13%] text-center">
                                        <span className="text-base font-bold">
                                          {t('general.total')}
                                        </span>
                                        <br />
                                        <span className="text-2xs">
                                          <br />
                                        </span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="text-sm divide-y divide-gray-100 ">
                                    {statistics?.kidsList
                                      .filter((k: any) => {
                                        const kid = kids?.find(
                                          (kid) => kid.id === k.kidId,
                                        )
                                        /* if (fetchOnlyArchived) {
                                          return kid
                                        } else {*/
                                        return (
                                          kid?.attributes?.departmentPointer
                                            ?.id === dep?.id
                                        )
                                        //}
                                      })
                                      ?.filter((k: any) => {
                                        if (search) {
                                          return k?.kidName
                                            ?.toLowerCase()
                                            .includes(search.toLowerCase())
                                        } else {
                                          return k
                                        }
                                      })
                                      .sort((a: any, b: any) => {
                                        if (
                                          sortBy === SORT_VALUES.FIRSTNAMEASC
                                        ) {
                                          return a?.kidName.localeCompare(
                                            b?.kidName,
                                          )
                                        } else if (
                                          sortBy === SORT_VALUES.FIRSTNAMEDESC
                                        ) {
                                          return b?.kidName.localeCompare(
                                            a?.kidName,
                                          )
                                        } else if (
                                          sortBy === SORT_VALUES.LASTNAMEASC
                                        ) {
                                          return a?.kidLastName.localeCompare(
                                            b?.kidLastName,
                                          )
                                        } else if (
                                          sortBy === SORT_VALUES.LASTNAMEDESC
                                        ) {
                                          return b?.kidLastName.localeCompare(
                                            a?.kidLastName,
                                          )
                                        }
                                      })
                                      .map((kid: any, index: number) => {
                                        return (
                                          <>
                                            <ShowWeeks
                                              key={kid.id}
                                              kidStatistics={kid}
                                              firstDate={moment(
                                                week.fromDate,
                                              ).startOf('week')}
                                              firstDateOfWeek={moment(
                                                week.fromDate,
                                              ).startOf('week')}
                                              lastDate={moment(
                                                week.toDate,
                                              ).endOf('week')}
                                              showWeekNumber={false}
                                              showKidInfo={true}
                                              schoolId={schoolId}
                                              fetch={() => {
                                                getData()
                                              }}
                                              fromDate={statisticsFromDate!}
                                              toDate={statisticsToDate!}
                                              exporting={exporting}
                                              showSsn={showSsn}
                                              sortBy={sortBy}
                                            />
                                          </>
                                        )
                                      })}
                                  </tbody>
                                </table>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      {/* End week for week new */}
                    </>
                  )}
                </>
              )
            })}
          </div>
        </div>
      </ColorCard>
      <div className=" overflow-hidden mb-12">
        <section className="antialiased bg-gray-100 text-gray-600"></section>

        {showSchema && (
          <>
            <div className="grid grid-cols-2 sm:grid-cols-4 justify-center space-x-12 mt-5 mb-7">
              <Button
                size="md"
                loading={loading}
                type="submit"
                variant="primary"
                label={t('statistics.getData')}
                onClick={() => getData()}
                //disabled={isSubmitting}
              />

              <Button
                size="md"
                loading={loading}
                type="submit"
                variant="primary"
                label={t('statistics.dailyView')}
                onClick={() => {
                  setTypeOfView('day')
                  setAmountOfDays(1)
                  setFirstDate(moment().startOf('day'))
                  setLastDate(moment().endOf('day'))
                  getData()
                }}
                //disabled={isSubmitting}
              />
              <Button
                size="md"
                loading={loading}
                type="submit"
                variant="primary"
                label={t('statistics.weeklyView')}
                onClick={() => {
                  setTypeOfView('oneWeek')
                  setAmountOfDays(7)
                  setFirstDate(moment().startOf('week'))
                  setLastDate(moment().endOf('week'))
                  getData()
                }}
              />
              <Button
                size="md"
                loading={loading}
                type="submit"
                variant="primary"
                label={t('statistics.monthlyView')}
                onClick={() => {
                  setTypeOfView('month')
                  setAmountOfDays(moment().daysInMonth())
                  setFirstDate(moment().startOf('month'))
                  setLastDate(moment().endOf('month'))
                  getData(moment().startOf('month'), moment().endOf('month'))
                }}
              />
            </div>
            <div className="rounded shadow-xl w-full">
              <div className="flex justify-end">
                <div className="m-5" onClick={() => dateBackward()}>
                  <Button icon={faChevronCircleLeft} />
                </div>
                <div className="m-5" onClick={() => dateForward()}>
                  <Button icon={faChevronCircleRight} />
                </div>
              </div>
              <div className="flex">
                <div className="w-[200px] border-r border-b bg-eventsMain text-white flex justify-center items-center">
                  <div className="align-middle">Barn</div>
                </div>
                <div className="flex-1 h-[90px]">
                  <HeaderDays
                    firstDate={firstDate}
                    amountOfDays={amountOfDays}
                    typeOfView={typeOfView}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="w-[200px] border-r border-l">
                  {statistics?.kidsList?.map((kid: any) => {
                    return (
                      <div className="w-full border-b h-[70px] text-center flex justify-center items-center">
                        <div>
                          {kid.kidName}
                          <br />{' '}
                          {showSsn && (
                            <td className="p-2 pl-16 lg:pl-0 whitespace-nowrap text-left italic">
                              {kid.kidSsn}
                            </td>
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className="flex-1 overflow-hidden">
                  {statistics?.kidsList?.map((kid: any) => {
                    return (
                      <div className="w-full border-b relative h-[70px]">
                        {kid.logs.length ? (
                          <CheckInOutBlock
                            logs={kid.logs}
                            firstDate={firstDate}
                            amountOfDays={amountOfDays}
                            typeOfView={typeOfView}
                          />
                        ) : null}
                        {kid.kidSchedule.length ? (
                          <ScheduleBlock
                            schedule={kid.kidSchedule}
                            firstDate={firstDate}
                            amountOfDays={amountOfDays}
                            typeOfView={typeOfView}
                          />
                        ) : null}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Page>
  )
})
