import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleCheck,
  faCircleExclamation,
  faCircleXmark,
  faEllipsisV,
  faEnvelope,
  faEye,
  faEyeSlash,
  faFilePdf,
  faMapMarkerAlt,
  faMapPin,
  faPaperclip,
  faPen,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { EventListItemData, IFile } from '../Events3'
import { EventDetailPage, IEventDetails } from '../EventDetailPage'
import {
  EVENT3_TYPE,
  Event3StoreContext,
  EventTimeSmallInterface,
} from '../../../store/event3Store'
import { LoadingBar } from '../../../components/LoadingBar'
import { EventTagLabel } from './EventTagLabel'
import { Department } from '../../../Models/Department'
import { TagStoreContext } from '../../../store/tagStore'
import { useParams } from 'react-router-dom'
import { faWaveform } from '@fortawesome/pro-solid-svg-icons'
import { UserStoreContext } from '../../../store/userStore'
import { Button } from '../../../components/Button'
import { useClickOutside } from '../../../hooks/useClickoutside'
import { USER_ROLE_TYPE } from '../../../Enums/TyraEnums'
import { ButtonDropdown } from '../../../components/Dropdown'
import { Loading } from '../../../components/Loading'
import { KidsStoreContext } from '../../../store/kidsStore'
import { RebookTime } from './RebookTime'
import { Kid } from '../../../Models/Kid'
import { UserRole } from '../../../Models/UserRole'
import { BookingFinalStage } from './BookingFinalStage'
import Swal from 'sweetalert2'
import { CommentInput } from './CommentInput'
import { CommentDetailCard } from './CommentDetailCard'

interface EventDetailCardProps {
  event: EventListItemData
  setFetchedEventDetail?: (value: EventListItemData) => void
  fetchedEventOverview?: IEventDetails
  loading?: boolean
  setLoading?: (value: boolean) => void
  setPopupVisible?: (value: boolean) => void
  isOverviewVisible?: boolean
  setOverviewVisible?: (value: boolean) => void
  onOpenSetting?: () => void
  triggerHasResponded?: () => void
  openSetting?: boolean
  isExpanded?: boolean
  onClick?: () => void
  dataAction?: string
}

export const getImageFromUrl = (imageUrl: string | undefined) => {
  if (imageUrl) {
    return imageUrl
  }
  return process.env.PUBLIC_URL + '/images/placeholder-image.png'
}

export const EventDetailCard: React.FC<EventDetailCardProps> = ({
  event,
  dataAction,
}) => {
  const { t } = useTranslation()
  const isFirstRender = useRef(true)
  const [isExpanded, setIsExpanded] = useState(false)
  const [loading, setLoading] = useState(false)
  const handleCardClick = () => {
    if (!isExpanded) {
      setLoading(true)
      fetchEventDetail().finally(() => {
        setLoading(false)
      })
    }
    setIsExpanded(!isExpanded) // Toggle expansion on click
  }
  const { getDetailedEvent } = useContext(Event3StoreContext)
  const [fetchedEventDetail, setFetchedEventDetail] =
    useState<EventListItemData>()

  const [hasResponded, setHasResponded] = useState(false)

  const triggerHasResponded = () => {
    setHasResponded((prev) => !prev)
  }
  const fetchEventDetail = useCallback(async () => {
    let isMounted = true
    try {
      const eventDetail = await getDetailedEvent(event.eventTimeId)
      if (isMounted) {
        setFetchedEventDetail(eventDetail)
      }
    } catch (error) {
      console.error('Error fetching event detail: ', error)
    }

    return () => {
      isMounted = false
    }
  }, [event.eventTimeId])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    fetchEventDetail()
  }, [hasResponded, fetchEventDetail])

  return (
    <div
      className={`w-full mb-3 flex rounded-2xl overflow-y-auto  cursor-pointer shadow-sm bg-white ${
        !isExpanded || (loading && 'cursor-pointer')
      } ${event.type === 'important' ? 'bg-[#FFE6E6]' : ''} ${
        isExpanded && !loading && 'max-h-[400px]'
      } `}
      //onClick={handleCardClick}
    >
      {!isExpanded || loading ? (
        <EventExpendableCardHeader
          event={event}
          loading={loading}
          triggerHasResponded={triggerHasResponded}
          onClick={() => handleCardClick()}
          dataAction={dataAction}
        />
      ) : (
        <EventDetailPage
          eventTimeId={event.eventTimeId}
          fetchedEventDetail={fetchedEventDetail}
          triggerHasResponded={triggerHasResponded}
          hasResponded={hasResponded}
          isExpanded={isExpanded}
          handleExpaneded={handleCardClick}
          setFetchedEventDetail={(value: EventListItemData) =>
            setFetchedEventDetail(value)
          }
        />
      )}
    </div>
  )
}
export const eventTypeColor = (type: string) => {
  switch (type) {
    case 'activities':
      return 'border-greenForrest'
    case 'meeting':
      return 'border-groupsMain'
    case 'info':
      return 'border-eventsMain'
    case 'important':
      return 'border-absenceMain'
    case 'booking':
      return 'border-purpleLight'
    default:
      return 'border-[#57d945]'
  }
}

export const EventExpendableCardHeader: React.FC<EventDetailCardProps> = ({
  event,
  setFetchedEventDetail,
  fetchedEventOverview,
  setLoading,
  setPopupVisible,
  isOverviewVisible,
  setOverviewVisible,
  loading,
  onOpenSetting,
  openSetting,
  isExpanded,
  onClick,
  triggerHasResponded,
  dataAction,
}) => {
  const { t } = useTranslation()

  const { allTagsForEvent } = useContext(TagStoreContext)
  const { schoolId } = useParams()
  const { currentUserRole } = useContext(UserStoreContext)
  const {
    getEvent3Overview,
    declineParticipatingInEvent,
    rebookToEventTime,
    getEvents,
    editBookingToEventTime,
    declineBookingToEventTime,
  } = useContext(Event3StoreContext)
  const { fetchGuardiansForKids, guardians } = useContext(KidsStoreContext)
  const [presentGuardians, setPresentGuardians] = useState<UserRole[]>([])
  const [showEditAttendees, setShowEditAttendees] = useState(false)

  const [showRebook, setShowRebook] = useState(false)
  const [showCancelBooking, setShowCancelBooking] = useState(false)
  const [choosePresentGuardians, setChoosePresentGuardians] = useState(false)
  const [timeRebookable, setTimeRebookable] = useState(true)

  const [selectedTime, setSelectedTime] =
    useState<EventTimeSmallInterface | null>(null)
  const [message, setMessage] = useState('')
  /* const [isPopupVisible, setPopupVisible] = useState(false)
  const [isOverviewVisible, setOverviewVisible] = useState(false) */

  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [menuRef, togglerRef] = useClickOutside(() => {
    setDropdownVisible(false)
  })

  const handlePlayAudio = (file: IFile) => {
    const newWindow = window.open('', '_blank')
    newWindow?.document.write('<!DOCTYPE html>')
    newWindow?.document.write('<html lang="en">')
    newWindow?.document.write('<head>')
    newWindow?.document.write('<meta charset="UTF-8">')
    newWindow?.document.write(
      '<meta name="viewport" content="width=device-width, initial-scale=1.0">',
    )
    newWindow?.document.write('<title>Audio Player</title>')
    newWindow?.document.write('<style>')
    newWindow?.document.write(`
  body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
  }
  audio {
    width: 50%;
    max-width: 500px;
  }
`)
    newWindow?.document.write('</style>')
    newWindow?.document.write('</head>')
    newWindow?.document.write('<body>')
    newWindow?.document.write('<audio controls autoplay>')
    newWindow?.document.write(
      `<source src="${file.file}" type="${file.mime_type}">`,
    )
    newWindow?.document.write(
      'Your browser does not support the audio element.',
    )
    newWindow?.document.write('</audio>')
    newWindow?.document.write('</body>')
    newWindow?.document.write('</html>')
    newWindow?.document.close()
  }

  /* const handleOpenPopup = () => {
    setPopupVisible(true)
  }

  const handleClosePopup = () => {
    setPopupVisible(false)
  } */
  const validUserToSeeOverview = currentUserRole?.role_type
    ? currentUserRole?.role_type > USER_ROLE_TYPE.PARENT
    : false
  const eventDate = moment(event?.eventDate)
  const eventDay = eventDate.format('DD')
  const eventMoment = eventDate.format('MM')
  const createdAt = moment(event?.createdAt).format('DD MMMM YYYY')
  const tagLable = event.tags
    ?.map(
      (eventTag) =>
        allTagsForEvent?.find((tag) => tag.name === eventTag.name)?.name,
    )
    .filter(Boolean)

  const locationType = event?.location?.type
  const hasComments =
    event?.type === EVENT3_TYPE.BOOKING
      ? event?.comments && event?.comments.length > 0
        ? true
        : event.allowComments
      : event?.comments
      ? event?.comments.length >= 0
      : false
  const sortedComments = event?.comments

  const displayAttachmentsContainer = (
    fetchedEventDetail?: EventListItemData,
  ) => {
    return (
      <div
        className={`flex  justify-start overflow-x-auto gap-x-1 w-full min-h-[83px] items-center pl-3 mb-3`}
        onClick={(e) => {
          e.stopPropagation()
          console.log(fetchedEventDetail)
        }}
      >
        {/********************************* attachments **************************************** */}
        {fetchedEventDetail?.files?.map((file) => (
          <div id="attachments-content" className="flex " key={file.id}>
            {(file.mime_type === 'image/jpeg' ||
              file.mime_type === 'image/png') && (
              <img
                src={file.file}
                alt=""
                className={`flex flex-row rounded-md object-cover h-16 w-16 mx-1 cursor-pointer shadow-sm`}
                onClick={(e) => {
                  e.stopPropagation()
                  window.open(file.file, '_blank')
                }}
              />
            )}
            {file.mime_type === 'audio/mpeg' && (
              <div
                className={`flex flex-col border-2 justify-center mx-1 items-center rounded-md h-16 w-16 border-blue-300 cursor-pointer shadow-sm`}
                onClick={(e) => {
                  e.stopPropagation()
                  handlePlayAudio(file)
                }}
              >
                <FontAwesomeIcon
                  icon={faWaveform}
                  className="w-full text-sm text-blue-500"
                />
                <p className="text-sm">
                  {moment.utc(file.metadata.durationInMs).format('m:ss')}
                </p>
              </div>
            )}
            {file.mime_type === 'application/pdf' && (
              <div
                className={`flex flex-col border-2 justify-center items-center rounded-md h-16 w-16 mx-1  border-red-300 cursor-pointer text-red-500 shadow-sm p-1`}
                onClick={(e) => {
                  e.stopPropagation()
                  window.open(file.file, '_blank')
                }}
              >
                <FontAwesomeIcon icon={faFilePdf} size="xl" />
                <p className="text-2xs text-center w-[95%] break-words line-clamp-2">
                  {file.name}
                </p>
              </div>
            )}
          </div>
        ))}
        {fetchedEventDetail?.location && (
          <div
            id="location-container"
            className={`rounded-md flex flex-col w-auto h-16 ml-2 text-xs bg-white justify-center p-2 border-2 border-greenForrest`}
          >
            <div className="flex justify-start items-center gap-2 p-2 whitespace-nowrap">
              <p className="flex flex-col font-semibold text-gray-700 w-full">
                <div className="flex">
                  <FontAwesomeIcon
                    icon={faMapPin}
                    size="sm"
                    className="text-blueDark pr-1"
                  />
                  {fetchedEventDetail?.location?.name}
                </div>
                {locationType === 'address' ? (
                  <>
                    <div>{fetchedEventDetail?.location?.address}</div>
                    <div>
                      {fetchedEventDetail?.location?.zipcode}{' '}
                      {fetchedEventDetail?.location?.city}
                    </div>
                  </>
                ) : locationType === 'coord' ? (
                  <div>
                    {fetchedEventDetail?.location?.lat}
                    {'°, '}
                    {fetchedEventDetail?.location?.long}
                    {'°'}
                  </div>
                ) : (
                  <></>
                )}
              </p>
              {fetchedEventDetail?.location?.type !== 'freetext' ? (
                <div className="flex justify-end w-[80%] pl-2">
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    size="lg"
                    className="text-[#6BBD73]"
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
        <div className="flex justify-between p-2  items-center my-2"></div>
      </div>
    )
  }
  const infoType = event?.type === EVENT3_TYPE.INFO
  const bookingType = event?.type === EVENT3_TYPE.BOOKING
  const eventHasPassed = moment(event?.eventDate).isBefore(
    moment().startOf('day'),
  )
  const handleDeclineParticipatingInEvent = async () => {
    await declineParticipatingInEvent(
      event?.eventTimeId,
      [currentUserRole?.user?.id ?? ''],
      [currentUserRole?.kid?.id ?? ''],
    )
    triggerHasResponded?.()
  }

  const handleAcceptParticipatingInEvent = async () => {
    setPopupVisible?.(true)
    triggerHasResponded?.()
  }

  const handleEditParticipating = (
    fetchedEventDetail: EventListItemData | undefined,
  ) => {
    let content = null

    if (!eventHasPassed) {
      if (fetchedEventDetail?.bookingStatus === 'attending') {
        if (
          fetchedEventDetail?.ownParticipantsData?.extraParticipants ===
          undefined
        ) {
          content = (
            <div className="flex flex-col justify-center items-center w-full mt-auto">
              <p className="font-semibold">
                {fetchedEventDetail.eventFor === 'kid' &&
                currentUserRole?.attributes.kid
                  ? currentUserRole?.attributes.kid.kidName +
                    ' ' +
                    t('events.registered')
                  : t('events.youRegistered')}
              </p>
              <p className="font-semibold my-1 px-3 w-[90%] flex justify-center text-sm">
                {fetchedEventDetail?.ownParticipantsData?.message ?? ''}{' '}
              </p>
              <p
                className="font-semibold text-blue-600 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation()
                  setPopupVisible?.(true)
                }}
              >
                {t('blog.edit')}
              </p>
            </div>
          )
        } else {
          content = (
            <div className="flex flex-col justify-center items-center w-full mt-auto">
              <p className="font-bold">
                {fetchedEventDetail?.ownParticipantsData?.extraParticipants + 1}{' '}
                {t('events.manyRegistered')}
              </p>
              <p className="my-1 px-3 w-[90%] flex justify-center">
                {fetchedEventDetail?.ownParticipantsData?.message ?? ''}{' '}
              </p>
              <p
                className="font-semibold  text-blue-600 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation()
                  setPopupVisible?.(true)
                }}
              >
                {t('blog.edit')}
              </p>
            </div>
          )
        }
      } else if (fetchedEventDetail?.bookingStatus === 'not_attending') {
        content = (
          <div className="flex flex-col justify-center items-center w-full mt-auto">
            <p className="font-semibold">{t('events.youDeclined')}</p>
            <p className="font-semibold my-1 px-3 w-[90%] flex justify-center">
              {fetchedEventDetail?.ownParticipantsData?.message ?? ''}{' '}
            </p>
            <p
              className="font-semibold text-blue-600 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation()
                setPopupVisible?.(true)
              }}
            >
              {t('blog.edit')}
            </p>
          </div>
        )
      }
    }

    return <>{content}</>
  }
  const commentsContainerRef = useRef<HTMLDivElement | null>(null) // Ref for the scrollable comments container

  const scrollToBottom = () => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop =
        commentsContainerRef.current.scrollHeight // Scroll to the bottom
    }
  }

  useEffect(() => {
    scrollToBottom() // Scroll to the bottom whenever sortedComments changes
  }, [sortedComments])

  return (
    <div
      className={`flex flex-col w-full p-3`}
      onClick={() => {
        if (onClick) onClick()
      }}
    >
      <div className={` border-l-4 ml-2 ${eventTypeColor(event.type)}`}>
        <div className={`w-full flex py-1 items-center text-xs pl-3`}>
          <div className="font-semibold text-black text-xs flex  gap-x-2 w-full justify-between">
            <div className="flex gap-x-2 ">
              <div className="font-normal">{eventDay + '/' + eventMoment}</div>
              {isExpanded ? (
                <div>
                  <span>
                    {event.times.length && event.times[0].start_time
                      ? `${event.times[0].start_time}-`
                      : ''}
                  </span>
                  <span>
                    {event.times.length && event.times[0].end_time
                      ? `${event.times[0].end_time}`
                      : ''}
                  </span>
                </div>
              ) : (
                <div>
                  <span>{event.endTime ? `${event.times[0].start}-` : ''}</span>
                  <span>{event.endTime ? `${event.times[0].end}` : ''}</span>
                </div>
              )}
            </div>

            <div>
              {event.onlyStaff && (
                <div className="text-redLight flex justify-start w-full font-semibold mr-2">
                  {t('documents.staff')}
                </div>
              )}
              {/* {event.tags && event.tags.length && (
                <EventTagLabel
                  thinText
                  color={event.tags[0].color}
                  label={(tagLable && tagLable[0]) ?? ''}
                />
              )} */}
            </div>
          </div>

          {/* May use this later so DO NOT delete */}
          {/* {event.isFileAttached && (
            <FontAwesomeIcon icon={faPaperclip} className="ml-2" />
          )} */}
        </div>
        <div className="pl-3">
          {event.tags && event.tags.length && (
            <EventTagLabel
              thinText
              color={event.tags[0].color}
              label={(tagLable && tagLable[0]) ?? ''}
            />
          )}
        </div>
        <div className="flex w-full pl-1">
          <div className={`w-full flex justify-center flex-col`}>
            <div className="flex items-center w-full justify-end">
              <div className="pt-2 h-full">
                {event.type === EVENT3_TYPE.BOOKING && (
                  <div className="w-10 h-10">
                    <img
                      src={getImageFromUrl(event.kidImageUrl)}
                      className="w-10 h-10  rounded-full"
                    />
                  </div>
                )}
              </div>
              <div
                className={`flex w-full items-center justify-center ${
                  !event.content ? 'py-1' : ''
                }`}
              >
                {!isExpanded && !event.isRead && (
                  <div className="w-3 h-3 bg-[#7eb5f4] rounded-full mr-2"></div>
                )}
                <div
                  className={`flex font-semibold pl-2 w-full justify-between items-center ${
                    !event.content ? 'flex justify-center' : ''
                  }`}
                >
                  {event.type === EVENT3_TYPE.BOOKING ? (
                    <div>
                      <div className="text-xs font-light">
                        {event.tags && event.tags.length ? (
                          <EventTagLabel
                            thinText
                            color={event.tags[0].color}
                            label={event.tags[0].name ?? ''}
                          />
                        ) : (
                          event.topic
                        )}
                      </div>
                      <h3>{event.kidFullName ?? event.kidName}</h3>
                      {event.eventResponsible ? (
                        <div className="mt-2">
                          <div className="text-xs font-semibold">
                            {t('events.responsible')}:
                          </div>
                          <div className="text-xs font-light">
                            {event.eventResponsible
                              ?.map((eventResp) => {
                                return eventResp.title
                              })
                              .join(', ')}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <div className="flex w-full">
                      <div className="text-xs font-light">
                        {/* {event.tags && event.tags.length ? (
                          <EventTagLabel
                            thinText
                            color={event.tags[0].color}
                            label={(tagLable && tagLable[0]) ?? ''}
                          />
                        ) : null} */}
                      </div>
                      <h3>{event.topic}</h3>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center whitespace-nowrap">
                <span className="flex justify-end font-normal items-center  w-[80px]">
                  {/* This will be used later dont remove */}
                  {(process.env.REACT_APP_PUBLIC_URL?.includes('localhost') ||
                    process.env.REACT_APP_PUBLIC_URL?.includes('alfa')) &&
                    isExpanded && (
                      <div
                        className={`flex items-center justify-center mx-2 rounded-full w-[25px] h-[25px] hover:bg-gray-200 animate-fadeIn ${
                          openSetting && ' bg-gray-200 '
                        }`}
                      >
                        <FontAwesomeIcon
                          icon={faEllipsisV}
                          size="lg"
                          className={`text-gray-400 cursor-pointer px-4`}
                          onClick={(e) => {
                            e.stopPropagation()
                            if (onOpenSetting) onOpenSetting()
                          }}
                        />
                      </div>
                    )}
                </span>
              </div>
            </div>

            {event.bookingStatus ? (
              <p className="text-gray-700 font-bold pt-2 pl-3 animate-pulse duration-700">
                {event.bookingStatus === 'not_replied'
                  ? t('events.notReplied')
                  : event.bookingStatus === 'not_attending'
                  ? t('events.notComing')
                  : event.bookingStatus === 'attending'
                  ? t('events.attending')
                  : event.bookingStatus}
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="relative">
          <p
            className={`pl-4 w-full my-2 ${
              !isExpanded && 'line-clamp-2 text-gray-800 '
            }`}
          >
            {event.content}
          </p>
          {!isExpanded && (
            <span className="absolute bottom-1 -right-2 text-blueDark font-semibold text-xs bg-white w-[90px] ">
              {dataAction !== 'multiDelete' ? t('events.showMore') : ''}
            </span>
          )}
        </div>
        {isExpanded &&
          (event.files?.length!! > 0 || event.location) &&
          displayAttachmentsContainer(event)}
        <div className="flex flex-col mt-2 justify-start animate-fadeIn">
          {/*********************************** Attachments (all events) container ************************************** */}
          {/* {displayAttachmentsContainer(fetchedEventDetail)} */}

          {/********************************* Post av info **************************************** */}

          {/************************************* Makw a resppnse yes/no for req a res events ************************************ */}
          {isExpanded && (
            <div
              className={`h-3/7 pb-2 flex flex-col justify-between animate-fadeIn`}
            >
              <div>
                {!eventHasPassed && event?.bookingStatus === 'not_replied' && (
                  <div className="flex flex-col items-center justify-center w-full">
                    <p className="font-semibold pb-2 text-sm">
                      {t('events.willYouParticipate')}
                    </p>
                    <div className="flex justify-center items-center gap-2">
                      <Button
                        size="xs"
                        variant="borderRed"
                        label={t('general.no') + ' 👎'}
                        onClick={async (e) => {
                          e.stopPropagation()
                          await handleDeclineParticipatingInEvent()
                        }}
                      />
                      <Button
                        size="xs"
                        variant="borderGreen"
                        label={t('general.yes') + ' 👍'}
                        onClick={async (e) => {
                          e.stopPropagation()
                          await handleAcceptParticipatingInEvent()
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              {/*************************************** Response status for req a res events ********************************** */}
              <div>
                {event?.bookingStatus === 'fully_booked' && (
                  <p className="flex w-[24%] mx-[38%] justify-center font-bold text-sm h-[75%] items-end">
                    {t('events.fullyBooked') + '  🥳'}
                  </p>
                )}
              </div>
              {/************************************************************************* */}
              {handleEditParticipating(event)}
              {/************************************************************************* */}
              <div>
                {event?.statusSummary && event.type !== EVENT3_TYPE.BOOKING && (
                  <div
                    id="status-summary"
                    className="flex justify-center w-full items-center gap-2 my-2 text-sm animate-fadeIn"
                  >
                    <FontAwesomeIcon
                      size="sm"
                      icon={faCircleCheck}
                      className="text-[#6BBD73]"
                    />
                    <p className="font-semibold">
                      {fetchedEventOverview?.acceptedCount}
                    </p>
                    <FontAwesomeIcon
                      size="sm"
                      icon={faCircleXmark}
                      className="text-red-400"
                    />
                    <p className="font-semibold">
                      {fetchedEventOverview?.declinedCount}
                    </p>
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      size="sm"
                      className="text-purple-400"
                    />
                    <p className="font-semibold">
                      {fetchedEventOverview?.notRepliedCount}
                    </p>
                    <div className="rounded-full w-3 h-3 bg-gray-400 flex items-center justify-center">
                      <FontAwesomeIcon
                        size="2xs"
                        icon={faEnvelope}
                        className="text-gray-100"
                      />
                    </div>
                    <p className="font-semibold">
                      {fetchedEventOverview?.hasMessageCount}
                    </p>
                  </div>
                )}
              </div>
              {validUserToSeeOverview &&
                (event?.type === 'info' || event?.type === 'important') && (
                  <div className={`flex justify-center mb-2`}>
                    <div className="flex items-center text-sm">
                      <FontAwesomeIcon
                        size="xs"
                        icon={faEye}
                        className="text-green-400 px-1"
                      />
                      {t('documents.read') + ': '}
                      <p className="font-bold pl-2">
                        {fetchedEventOverview?.readCount}
                      </p>
                    </div>
                    <div className="flex items-center text-sm">
                      <FontAwesomeIcon
                        size="xs"
                        icon={faEyeSlash}
                        className="text-gray-400 px-1"
                      />
                      {t('documents.unread') + ': '}
                      <p className="font-bold pl-2">
                        {fetchedEventOverview?.unreadCount}
                      </p>
                    </div>
                  </div>
                )}
              {/************************************************************************* */}
              {event?.type === EVENT3_TYPE.BOOKING ? (
                <>
                  <div className={`flex justify-center w-full p-1 relative `}>
                    <Button
                      ref={togglerRef}
                      className="w-[200px]"
                      size="sm"
                      variant="fullBlue"
                      fontSize="font-bold"
                      label={t('blog.edit')}
                      onClick={(e) => {
                        e.stopPropagation()
                        setDropdownVisible(!dropdownVisible)
                      }}
                    />
                    <div className="absolute flex top-[-120px] left-[69%]">
                      {dropdownVisible && (
                        <div
                          className="absolute right-[100%] z-50 mx-3 origin-top divide-y w-56 bottom-10"
                          style={{
                            display: dropdownVisible ? 'block' : 'none',
                          }}
                          ref={menuRef}
                        >
                          <ButtonDropdown.Menu>
                            <ButtonDropdown.Section>
                              <ButtonDropdown.Button
                                key={'showArchived'}
                                icon={faPen}
                                label={t('events.reBook')}
                                className="text-yellow-600"
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setShowRebook(true)
                                }}
                              />
                              <ButtonDropdown.Button
                                key={'showArchived'}
                                icon={faTrashCan}
                                label={t('events.unbook')}
                                className="text-red-600"
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setShowCancelBooking(true)
                                }}
                              />
                              <ButtonDropdown.Button
                                key={'showArchived'}
                                icon={faTrashCan}
                                label={t('events.editAttendees')}
                                className="text-purple-600"
                                onClick={async (e) => {
                                  e.stopPropagation()
                                  if (event.kidId) {
                                    await fetchGuardiansForKids(
                                      Kid.createWithoutData(event.kidId) as Kid,
                                      true,
                                    ).then(
                                      (response: UserRole[] | undefined) => {
                                        setPresentGuardians(
                                          response
                                            ? response?.filter(
                                                (guardian: UserRole) =>
                                                  event?.presentGuardians?.find(
                                                    (gua) =>
                                                      gua.id === guardian.id,
                                                  ),
                                              )
                                            : [],
                                        )
                                        setShowEditAttendees(true)
                                      },
                                    )
                                  }
                                }}
                              />
                            </ButtonDropdown.Section>
                          </ButtonDropdown.Menu>
                        </div>
                      )}
                    </div>
                  </div>
                  {loading && <Loading />}
                  {showRebook && !choosePresentGuardians && (
                    <RebookTime
                      onClose={() => {
                        setShowRebook(false)
                      }}
                      name={event.kidFullName}
                      selectedTime={selectedTime}
                      setSelectedTime={(value) => setSelectedTime(value)}
                      eventGroupInfoId={event.eventGroupInfoId}
                      onDone={() => setChoosePresentGuardians(true)}
                    />
                  )}
                  {(choosePresentGuardians ||
                    showEditAttendees ||
                    showCancelBooking) && (
                    <BookingFinalStage
                      isOpen={
                        choosePresentGuardians ||
                        showEditAttendees ||
                        showCancelBooking
                      }
                      onClose={() => {
                        setChoosePresentGuardians(false)
                        setMessage('')
                        if (!showEditAttendees) {
                          setTimeRebookable(true)
                        }
                        setPresentGuardians([])

                        setShowEditAttendees(false)
                        setShowCancelBooking(false)
                      }}
                      kidName={event.kidName ?? ''}
                      selectedTime={selectedTime}
                      //isStaff={isStaff ? true : false}
                      isStaff={
                        currentUserRole &&
                        currentUserRole?.role_type > USER_ROLE_TYPE.PARENT
                          ? true
                          : false
                      }
                      hideMessage={
                        showEditAttendees
                          ? true
                          : showCancelBooking
                          ? true
                          : event.allowComments
                          ? false
                          : true
                      }
                      title={
                        showEditAttendees
                          ? t('events.changeRegistration')
                          : showCancelBooking
                          ? t('events.cancelRegistration')
                          : t('events.confirmRescheduling')
                      }
                      timeRebookable={timeRebookable}
                      onTimeRebookableClick={() =>
                        setTimeRebookable(!timeRebookable)
                      }
                      rebook={true}
                      cancelBooking={showCancelBooking}
                      message={message}
                      setMessage={(message) => setMessage(message)}
                      guardians={guardians}
                      presentGuardians={presentGuardians}
                      onGuardianClick={(guardian: UserRole) => {
                        if (
                          presentGuardians.find((pG) => pG.id === guardian.id)
                        ) {
                          setPresentGuardians(
                            presentGuardians.filter(
                              (pG) => pG.id !== guardian.id,
                            ),
                          )
                        } else {
                          setPresentGuardians([...presentGuardians, guardian])
                        }
                      }}
                      onSave={async () => {
                        if (
                          currentUserRole &&
                          selectedTime?.id &&
                          event.kidId &&
                          !showEditAttendees &&
                          !showCancelBooking
                        ) {
                          setLoading?.(true)

                          await rebookToEventTime(
                            currentUserRole?.school_pointer.id,
                            currentUserRole?.id,
                            selectedTime.id,
                            event.eventTimeId,
                            [event.kidId],
                            timeRebookable ? false : true,
                            presentGuardians.map((guardian) => guardian.id),
                            message !== '' ? message : undefined,
                            true,
                          )
                            .catch((e) => {
                              Swal.fire({
                                //title: t('general.accountNotAvailible'),
                                text: t('events.alreadyBooked'),
                                icon: 'warning',
                                showCancelButton: false,
                                focusConfirm: false,
                                confirmButtonColor: '#7eb5f4',
                                cancelButtonColor: '#d33',
                                confirmButtonText: t('general.ok'),
                              })
                              //throw new Error()
                              //!! SWAL HÄR
                            })
                            .then(async () => {
                              /*await getEvents()
                                  .catch((e) => {
                                    throw new Error()
                                    //!! SWAL HÄR
                                  })
                                  .finally(async () => {
                                    
                                  })*/
                              setChoosePresentGuardians(false)
                              setMessage('')
                              setTimeRebookable(true)
                              setPresentGuardians([])
                              setShowRebook(false)
                              setSelectedTime(null)
                              setShowCancelBooking(false)
                              setLoading?.(false)
                            })
                        } else if (showEditAttendees && !showCancelBooking) {
                          await editBookingToEventTime(
                            event.eventTimeId,
                            event.ownParticipantsData.id,
                            undefined,
                            undefined,
                            undefined,
                            presentGuardians.map((guardian) => guardian.id),
                          )
                            .catch((e) => {
                              Swal.fire({
                                //title: t('general.accountNotAvailible'),
                                text: t('events.alreadyBooked'),
                                icon: 'warning',
                                showCancelButton: false,
                                focusConfirm: false,
                                confirmButtonColor: '#7eb5f4',
                                cancelButtonColor: '#d33',
                                confirmButtonText: t('general.ok'),
                              })
                              //throw new Error()
                              //!! SWAL HÄR
                            })
                            .then((response: any) => {
                              /*await getEvents()
                                  .catch((e) => {
                                    throw new Error()
                                    //!! SWAL HÄR
                                  })
                                  .finally(async () => {
                                    
                                  })*/
                              setChoosePresentGuardians(false)
                              setMessage('')
                              setTimeRebookable(true)
                              setPresentGuardians([])
                              setShowRebook(false)
                              setSelectedTime(null)
                              setLoading?.(false)
                              setShowEditAttendees(false)
                              setShowCancelBooking(false)

                              setFetchedEventDetail?.({
                                ...event,
                                presentGuardians: response.presentGuardians,
                              })
                            })
                        } else if (
                          showCancelBooking &&
                          currentUserRole?.school_pointer &&
                          event.kidId
                        ) {
                          await declineBookingToEventTime(
                            currentUserRole?.school_pointer.id,
                            currentUserRole?.id,
                            event.eventTimeId,
                            [event.kidId],
                            true,
                            timeRebookable ? false : true,
                          )
                            .catch((e) => {
                              Swal.fire({
                                text: t('events.alreadyBooked'),
                                icon: 'warning',
                                showCancelButton: false,
                                focusConfirm: false,
                                confirmButtonColor: '#7eb5f4',
                                cancelButtonColor: '#d33',
                                confirmButtonText: t('general.ok'),
                              })
                              //throw new Error()
                              //!! SWAL HÄR
                            })
                            .then((response: any) => {
                              /*await getEvents()
                                  .catch((e) => {
                                    throw new Error()
                                    //!! SWAL HÄR
                                  })
                                  .finally(async () => {
                                    
                                  })*/
                              setChoosePresentGuardians(false)
                              setMessage('')
                              setTimeRebookable(true)
                              setPresentGuardians([])
                              setShowRebook(false)
                              setSelectedTime(null)
                              setLoading?.(false)
                              setShowEditAttendees(false)
                              setShowCancelBooking(false)
                            })
                        }
                      }}
                    />
                  )}
                </>
              ) : (
                <div className="flex items-center justify-center relative mt-10">
                  {currentUserRole?.role_type &&
                    currentUserRole?.role_type > USER_ROLE_TYPE.PARENT && (
                      <div
                        className={`absolute bottom-0 left-1/2 transform -translate-x-1/2`}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Button
                          className="w-[200px]"
                          size="sm"
                          variant="fullBlue"
                          fontSize="font-bold"
                          label={t('documents.overview')}
                          onClick={(e) => {
                            setOverviewVisible?.(!isOverviewVisible)
                          }}
                        />
                      </div>
                    )}
                  {event?.type !== EVENT3_TYPE.BOOKING && (
                    <div className="flex absolute right-2 bottom-0">
                      <img
                        src={
                          event?.publisherImage ||
                          '/images/placeholder-image.png'
                        }
                        className={`rounded-full h-8 w-8 mr-1`}
                        alt={`${
                          event?.publisherImage ? 'Publisher Avatar' : ''
                        }`}
                      />
                      <div className="flex flex-col justify-center text-xs">
                        <p className="font-bold ">{event?.publisherName}</p>
                        <p className="text-gray-700">{createdAt}</p>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {isExpanded &&
        ((event?.onlyStaff && infoType) ||
          (event?.allowComments && bookingType)) && (
          <div className="mt-6 max-h-[320px]">
            {hasComments && (
              <div className="w-full animate-fadeIn">
                <p className="font-semibold text-gray120 pl-2 w-full mb-2 text-sm">
                  {event?.comments ? event?.comments.length : 0}{' '}
                  {t('blog.comments')}
                </p>
                <div
                  ref={commentsContainerRef}
                  className="max-h-72 overflow-y-auto scrollbar-hide"
                >
                  {sortedComments?.map((comment) => (
                    <CommentDetailCard {...comment} key={comment.id} />
                  ))}
                </div>
              </div>
            )}
            {((event?.onlyStaff && infoType) ||
              (event?.allowComments && bookingType)) && (
              <div
                className={`flex items-center justify-center  w-[90%] mx-auto h-14 sticky bottom-0 -mb-6 bg-gradient-to-t from-white/80`}
              >
                <CommentInput
                  eventTimeId={event.eventTimeId}
                  eventGroupInfoId={event.eventGroupInfoId}
                  refreshDetail={triggerHasResponded || (() => {})}
                />
              </div>
            )}
          </div>
        )}

      {loading && (
        <div className="w-full mt-2">
          <LoadingBar speed={2} />
        </div>
      )}
    </div>
  )
}
