import React, { RefObject, useContext, useState } from 'react'
import { faBorderAll } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalRegular from '../../components/ModalRegular'
import { Dashboard } from '../../views/dashboard/components/Dashboard'
import { observer } from 'mobx-react'
import { UserStoreContext } from '../../store/userStore'

export const DashboardButton: React.FC<{}> = observer(() => {
  const [showModal, setShowModal] = useState(false)
  const { savedUserRoleId } = useContext(UserStoreContext)

  return (
    <div className="relative flex items-end">
      <button
        type="button"
        className="w-full rounded-md group focus:outline-none"
        id="options-menu"
        aria-haspopup="true"
        aria-expanded="true"
        onClick={() => {
          setShowModal(true)
        }}
      >
        <div className="text-xl group-hover:text-blue-50">
          <FontAwesomeIcon icon={faBorderAll} />
        </div>
      </button>

      <ModalRegular
        title={''}
        isOpen={showModal}
        hideCloseButton
        onClose={() => {
          setShowModal(false)
        }}
        bgColor={' p-7'}
      >
        <div className={'pt-2 pb-3'}>
          <Dashboard
            hideHeader
            userRoleId={savedUserRoleId ?? ''}
            onClick={() => {
              setShowModal(false)
            }}
          />
        </div>
      </ModalRegular>
    </div>
  )
})
